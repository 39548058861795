"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProjectWriteRights = void 0;
var ProjectWriteRights = (0, _createClass2.default)(function ProjectWriteRights() {
  (0, _classCallCheck2.default)(this, ProjectWriteRights);
  this.canChangeAllTickets = false;
  this.commandActivatePlanVersion = false;
  this.commandAddTicketWatcher = false;
  this.commandArchiveTicket = false;
  this.commandAssignTicketTo = false;
  this.commandChangePlanDetails = false;
  this.commandChangeProject = false;
  this.commandChangeProjectCrafts = false;
  this.commandChangeProjectFileTypes = false;
  this.commandChangeProjectTags = false;
  this.commandChangeProjectTicketLayouts = false;
  this.commandChangeProjectTicketTypes = false;
  this.commandChangeProjectUserGroups = false;
  this.commandChangeProjectUserPhone = false;
  this.commandChangeStoredFileComment = false;
  this.commandChangeStoredFileType = false;
  this.commandChangeTicketApprover = false;
  this.commandChangeTicketCraft = false;
  this.commandChangeTicketDescription = false;
  this.commandChangeTicketForeignId = false;
  this.commandChangeTicketStatus = false;
  this.commandChangeTicketSubTicketFrom = false;
  this.commandChangeTicketTags = false;
  this.commandChangeTicketTitle = false;
  this.commandChangeTicketType = false;
  this.commandCreatePlan = false;
  this.commandCreatePlanFile = false;
  this.commandCreatePlanVersion = false;
  this.commandCreateProjectRole = false;
  this.commandCreateTicket = false;
  this.commandDeleteTicket = false;
  this.commandDiscardPlanFile = false;
  this.commandEditFolders = false;
  this.commandInviteUser = false;
  this.commandRemoveStoredFile = false;
  this.commandRemoveTicketWatcher = false;
  this.commandRemoveUser = false;
  this.commandRestoreTicket = false;
  this.commandSetTicketShouldCompleteDate = false;
  this.commandUnArchiveTicket = false;
  this.commandChangeProjectAddress = false;
  this.commandChangeProjectTemplateStatus = false;
  this.commandPublishViews = false;
});
exports.ProjectWriteRights = ProjectWriteRights;