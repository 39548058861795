import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React from 'react';
import { View } from 'react-native';
import { I18n } from '../../../i18n/I18n';
export function BulkChangeDialogConfirmation(props) {
    return (<View>
      <View style={{ padding: ThemeManager.style.contentPaddingValue, paddingBottom: 32 }}>
        <MaterialText type={MaterialTextTypes.H6}>
          {I18n.m.getMessage('bulkChangesConfirmationDialogHeader')}
        </MaterialText>
        <MaterialText>
          {I18n.m.getMessage('bulkChangesConfirmationDialogText', { count: props.selectedTickets })}
        </MaterialText>
      </View>
      <DialogActions>
        <ContainedButton title={I18n.m.getMessage('back')} onPress={props.onGoBack} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
        <ContainedButton title={I18n.m.getMessage('apply')} onPress={props.onConfirm}/>
      </DialogActions>
    </View>);
}
