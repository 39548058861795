import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { Linking, View } from 'react-native';
import { InfoButton } from 'materialTheme/src/theme/components/InfoButton';
import { I18n } from '../../../i18n/I18n';
export class TicketBuilder extends PureComponent {
    constructor(props) {
        super(props);
        this.openUrl = (url) => (_e) => {
            Linking.openURL(url).catch((err) => console.debug(err));
        };
    }
    render() {
        const { ticket, labelStyle } = this.props;
        if (ticket.builder != null) {
            return [
                <View key="TicketBuilderContent" style={{ width: '100%' }}>
          <View style={{ ...labelStyle, flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
            <View style={{ flex: 1 }}>
              <MaterialText type={MaterialTextTypes.Caption} numberOfLines={1}>
                {I18n.m.getMessage('ticketClient')}
              </MaterialText>
              <MaterialText>{ticket.builder.name}</MaterialText>
            </View>
            <InfoButton text={I18n.m.getMessage('ticketImportedInformation')}/>
          </View>
          {ticket.builder.address != null ? (<View style={{
                            ...labelStyle,
                            flexDirection: 'row',
                            width: '100%',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>
              <View key="TicketAddress" style={{ flex: 1 }}>
                <MaterialText fixedWidth="100%" type={MaterialTextTypes.Caption} numberOfLines={1}>
                  {I18n.m.getMessage('ticketClientAddress')}
                </MaterialText>
                <MaterialText>{ticket.builder.address.address}</MaterialText>
              </View>
              {ticket.builder.address.coordinates != null &&
                            ticket.builder.address.coordinates.lon != null &&
                            ticket.builder.address.coordinates.lat != null &&
                            ticket.builder.address.coordinates.lat !== 0 &&
                            ticket.builder.address.coordinates.lon !== 0 ? (<Icon onPress={this.openUrl(`https://www.google.de/maps/dir//'${ticket.builder.address.coordinates.lat},${ticket.builder.address.coordinates.lon}'/@${ticket.builder.address.coordinates.lat},${ticket.builder.address.coordinates.lon},18z`)} icon="directions" color="#FFFFFF" toolTip={I18n.m.getMessage('address')} backgroundColor={ThemeManager.style.brandPrimary}/>) : null}
            </View>) : null}
          {ticket.builder.phone != null ? (<View style={{
                            ...labelStyle,
                            flexDirection: 'row',
                            width: '100%',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>
              <View style={{ flex: 1 }}>
                <MaterialText fixedWidth="100%" type={MaterialTextTypes.Caption} numberOfLines={1}>
                  {I18n.m.getMessage('ticketClientPhone')}
                </MaterialText>
                <MaterialText>{ticket.builder.phone}</MaterialText>
              </View>
              <Icon onPress={this.openUrl(`tel:${ticket.builder.phone}`)} icon="phone" color={ThemeManager.style.brandPrimary} toolTip={I18n.m.getMessage('addressCall')} backgroundColor="#FFFFFF" borderStyle="solid"/>
            </View>) : null}
          {ticket.builder.email != null ? (<View style={{
                            ...labelStyle,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            height: 40,
                            width: '100%',
                            alignItems: 'center',
                        }}>
              <View style={{ flex: 1 }}>
                <MaterialText fixedWidth="100%" type={MaterialTextTypes.Caption} numberOfLines={1}>
                  {I18n.m.getMessage('ticketClientEmail')}
                </MaterialText>
                <MaterialText>{ticket.builder.email}</MaterialText>
              </View>
              <Icon onPress={this.openUrl(`mailto:${ticket.builder.email}`)} icon="email-outline" color={ThemeManager.style.brandPrimary} toolTip={I18n.m.getMessage('email')} backgroundColor="#FFFFFF" borderStyle="solid"/>
            </View>) : null}
        </View>,
            ];
        }
        return <View key="TicketBuilderBlank"/>;
    }
}
