import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { DateInput } from 'materialTheme/src/theme/components/forminput/DateInput';
import { DateRangeInput } from 'materialTheme/src/theme/components/forminput/DateRangeInput';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import NumberPicker from 'materialTheme/src/theme/components/NumberPicker';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { SliderV2 } from 'materialTheme/src/theme/components/SliderV2';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useState } from 'react';
import { View } from 'react-native';
import { I18n } from '../../../i18n/I18n';
export function BulkChangeDialogSingleFields(props) {
    const [selected, setSelected] = useState(undefined);
    const renderProgress = (changedTopic) => {
        const options = changedTopic.customField.options;
        const stepWidth = options.stepWidth ? options.stepWidth : 1;
        const start = options.start ? options.start : 0;
        let end = options.end ? options.end : 10;
        if (end <= start)
            end = start + stepWidth;
        return (<SliderV2 showValue onRelease={(v) => setSelected(v)} value={options.default ? options.default : start} minimumValue={start} maximumValue={end} stepWidth={stepWidth} showCurrentValue={false}/>);
    };
    const renderNumber = (changedTopic) => {
        const options = changedTopic.customField.options;
        const min = options.min ? options.min : 0;
        const max = options.max ? options.max : 5985;
        return (<NumberPicker formatTime={false} title={I18n.m.getMessage('bulkChangesNewValue')} min={min} max={max} value={0} onChange={(v) => setSelected(v)}/>);
    };
    const renderString = (changedTopic) => {
        const options = changedTopic.customField.options;
        return (<FormInputFilled labelText={I18n.m.getMessage('bulkChangesNewValue')} numberOfLines={options == null || options.multiline == null || options.multiline === false ? 1 : 5} onChange={(t) => {
                setSelected(t);
            }}/>);
    };
    const renderDate = (changedTopic) => {
        const options = changedTopic.customField.options;
        return (<DateInput labelText={changedTopic.label} selectTime={options.withTime === true} onChange={(d) => setSelected({ date: d })}/>);
    };
    const renderDateRange = (changedTopic) => {
        const options = changedTopic.customField.options;
        return (<DateRangeInput labelText={changedTopic.label} selectTime={options.withTime === true} onChange={(d) => setSelected(d)}/>);
    };
    const renderSingleFields = () => {
        const { changedTopic, onCancel, onSave } = props;
        if (typeof changedTopic === 'string' || changedTopic.customField == null)
            return <View />;
        let field = <View />;
        if (changedTopic.customField.type === 'string') {
            field = renderString(changedTopic);
        }
        else if (changedTopic.customField.type === 'number') {
            field = renderNumber(changedTopic);
        }
        else if (changedTopic.customField.type === 'progress') {
            field = renderProgress(changedTopic);
        }
        else if (changedTopic.customField.type === 'Date') {
            field = renderDate(changedTopic);
        }
        else if (changedTopic.customField.type === 'DateRange') {
            field = renderDateRange(changedTopic);
        }
        return (<View>
        <DialogTitle>{changedTopic.label}</DialogTitle>
        <DialogContent dialogHasTitle>
          <MaterialText>{changedTopic.description}</MaterialText>
          {field}
          <DialogActions onBottom>
            <ContainedButton title={I18n.m.getMessage('abort')} onPress={onCancel} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
            <ContainedButton title={I18n.m.getMessage('apply')} onPress={() => onSave(selected)}/>
          </DialogActions>
        </DialogContent>
      </View>);
    };
    return renderSingleFields();
}
