import React, { useEffect, useMemo, useRef, useState } from 'react';
import { View } from 'react-native';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { OpenableChip } from 'materialTheme/src/theme/components/chips/OpenableChip';
import { SegmentedButton } from 'materialTheme/src/theme/components/button/SegmentedButton';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { I18n } from '../../i18n/I18n';
var SortDirection;
(function (SortDirection) {
    SortDirection[SortDirection["ASC"] = 0] = "ASC";
    SortDirection[SortDirection["DESC"] = 1] = "DESC";
})(SortDirection || (SortDirection = {}));
const SUPPORTED_TYPES = new Set(['string', 'number', 'Date', 'person', 'progress', 'List']);
const TicketSortDialog = React.memo((props) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [sortDirection, setSortDirection] = useState(props.loadDirection === '1' ? SortDirection.DESC : SortDirection.ASC);
    const sortType = useRef('title');
    const defaultFields = [
        { title: I18n.m.getMessage('ticketsDetailsTitle'), sortBy: 'title' },
        { title: I18n.m.getMessage('ticketsDetailsNumber'), sortBy: 'number' },
        { title: I18n.m.getMessage('ticketsDetailsType'), sortBy: 'type' },
        { title: I18n.m.getMessage('ticketsDetailsCraft'), sortBy: 'craft' },
        { title: I18n.m.getMessage('ticketsDetailsState'), sortBy: 'state' },
        { title: I18n.m.getMessage('dashboardItemsAssignee'), sortBy: 'assignee' },
        { title: I18n.m.getMessage('ticketsDetailsTitleApprover'), sortBy: 'approver' },
        { title: I18n.m.getMessage('ticketDetailsDueDate'), sortBy: 'due' },
        { title: I18n.m.getMessage('ticketsDetailsLastEdit'), sortBy: 'edit' },
    ];
    const getAllFields = useMemo(() => {
        const result = [];
        for (const item of defaultFields) {
            result.push({
                title: item.title,
                onPressChipData: item.sortBy,
            });
        }
        const customTypes = new Set();
        for (const cTicket of props.tickets) {
            if (cTicket.fields != null) {
                for (const cField of cTicket.fields) {
                    if (!customTypes.has(cField.name) && SUPPORTED_TYPES.has(cField.type)) {
                        result.push({
                            title: cField.name,
                            onPressChipData: cField.name,
                        });
                    }
                    customTypes.add(cField.name);
                }
            }
        }
        return result;
    }, [props.tickets]);
    useEffect(() => {
        const index = getAllFields.findIndex((elem) => elem.onPressChipData === props.loadSortBy);
        if (index >= 0) {
            setSelectedIndex(index);
        }
    }, [props.loadSortBy]);
    const onChipChanged = (data) => {
        const index = getAllFields.findIndex((elem) => elem.onPressChipData === data);
        setSelectedIndex(index > 0 ? index : 0);
        sortType.current = data;
    };
    const applySort = () => {
        const { setSortBy, setDirection } = props;
        Dialog.instance?.close(() => {
            setSortBy(sortType.current);
            setDirection(sortDirection.toString());
        });
    };
    const resetSort = () => {
        const { setSortBy, setDirection } = props;
        Dialog.instance?.close(() => {
            setSortBy('');
            setDirection('0');
        });
    };
    return (<>
        <DialogContent>
          <View style={{ padding: 0 }}>
            <OpenableChip selected={selectedIndex} onPressChip={onChipChanged} looksLikeFI formInputLabel={I18n.m.getMessage('ticketsSortBy')} chipsList={getAllFields}/>
            <SegmentedButton buttons={[
            { title: 'Aufsteigend', key: 'sortASC', icon: { icon: 'sort-ascending' } },
            { title: 'Absteigend', key: 'sortDESC', icon: { icon: 'sort-descending' } },
        ]} onPress={(_data, button) => {
            if (button.key === 'sortASC') {
                setSortDirection(SortDirection.ASC);
            }
            else {
                setSortDirection(SortDirection.DESC);
            }
        }} singleSelectSelected={sortDirection === SortDirection.ASC ? 0 : 1}/>
          </View>
        </DialogContent>
        <DialogActions>
          <ContainedButton backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} title={I18n.m.getMessage('reset')} onPress={resetSort} key="restore"/>
          <ContainedButton title={I18n.m.getMessage('use')} onPress={applySort} key="apply"/>
        </DialogActions>
      </>);
});
export const openTicketSortDialog = (tickets, sortBy, sortDirection, setSortBy, setDirection) => {
    Dialog.instance?.open({
        title: I18n.m.getMessage('ticketsSortBy'),
        content: (<TicketSortDialog tickets={tickets} loadSortBy={sortBy} loadDirection={sortDirection} setSortBy={setSortBy} setDirection={setDirection}/>),
        contentPadding: false,
        fullscreenResponsive: true,
        fixedTitle: true,
        showCloseIcon: true,
        showCloseButton: false,
    });
};
