import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React from 'react';
import { View } from 'react-native';
import { I18n } from '../../../i18n/I18n';
export function BulkChangeDialogDeleteArchive(props) {
    const getHeadline = () => {
        const { topic } = props;
        if (topic === 'archive')
            return I18n.m.getMessage('archiveTicketsDialogHeadline');
        if (topic === 'unarchive')
            return I18n.m.getMessage('archiveRestoreTicketsDialogHeadline');
        if (topic === 'delete')
            return I18n.m.getMessage('deleteTicketsDialogHeadline');
        if (topic === 'restore')
            return I18n.m.getMessage('deleteRestoreTicketsDialogHeadline');
        return '';
    };
    const getQuestion = () => {
        const { topic } = props;
        if (topic === 'archive')
            return I18n.m.getMessage('archiveTicketsQuestion');
        if (topic === 'unarchive')
            return I18n.m.getMessage('archiveRestoreTicketsQuestion');
        if (topic === 'delete')
            return I18n.m.getMessage('deleteTicketsQuestion');
        if (topic === 'restore')
            return I18n.m.getMessage('deleteRestoreTicketsQuestion');
        return '';
    };
    return (<View style={{ padding: ThemeManager.style.contentPaddingValue }}>
      <MaterialText type={MaterialTextTypes.H6}>{getHeadline()}</MaterialText>
      <MaterialText>{getQuestion()}</MaterialText>
      <View style={{ alignSelf: 'flex-end', flexDirection: 'row', paddingTop: ThemeManager.style.contentPaddingValue }}>
        <ContainedButton title={I18n.m.getMessage('cancel')} onPress={props.onCancel} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
        <ContainedButton title={I18n.m.getMessage('apply')} onPress={props.onSave}/>
      </View>
    </View>);
}
