import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Card } from 'materialTheme/src/theme/components/Card';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Fab } from 'materialTheme/src/theme/components/Fab';
import { ExcelFileDialog } from 'materialTheme/src/theme/components/file/ExcelFileDialog';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { Platform, View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { AddCompanyTag } from 'upmesh-core/src/client/commands/companies/tags/AddCompanyTag';
import { RemoveCompanyTag } from 'upmesh-core/src/client/commands/companies/tags/RemoveCompanyTag';
import { InfoButton } from 'materialTheme/src/theme/components/InfoButton';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
import { AddOrChangeTagDialog } from './AddOrChangeTagDialog';
const noTagsImg = require('../../assets/img/no_types_or_crafts_or_tags.png');
export class CompanyTagsView extends PureComponent {
    constructor(props) {
        super(props);
        this.myRole = () => {
            return CompanyUserInfo.me == null ? 'user' : CompanyUserInfo.me.role;
        };
        this.removeTag = (tag) => (_e) => {
            Routing.instance.alert.post({
                text: I18n.m.getMessage('companyRemoveTagsQuestion', { tag }),
                buttons: [
                    <ContainedButton key="no" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                    <ContainedButton key="yes" title={I18n.m.getMessage('delete')} onPress={this.removeTagNow(tag)} backgroundColor={ThemeManager.style.brandDanger}/>,
                ],
            });
        };
        this.removeTagNow = (tag) => (_e) => {
            const { company } = this.props;
            LoadingEvents.instance.startLoading();
            Alert.instance?.close(() => {
                const remove = new RemoveCompanyTag({ tag }, company.id, CurrentUser.token);
                remove
                    .execute(ClientStore.commandStore)
                    .catch((e) => {
                    console.error(e);
                    Routing.instance.alert.post({ text: DefaultErrorHandler.getDefaultErrorMessages(e, I18n.m) });
                })
                    .finally(() => {
                    LoadingEvents.instance.stopLoading();
                });
            });
        };
        this.openExcelDialog = () => {
            Dialog.instance?.open({
                closeOnTouchOutside: false,
                fullscreenResponsive: true,
                contentPadding: false,
                scrollable: false,
                showCloseButton: false,
                width: 1042,
                content: (<ExcelFileDialog saveFunction={this.saveFunction} generateExampleRecords={this.generateExamples} columns={[
                        { title: I18n.m.getMessage('companyExcelTagNameHeader'), keyInData: 'Tagname', style: { width: 200 } },
                        { title: I18n.m.getMessage('companyExcelTagGroupHeader'), keyInData: 'Gruppe', style: { width: 200 } },
                    ]}/>),
            });
        };
        this.saveFunction = async (records) => {
            const { company, companySettings } = this.props;
            const tagPromises = [];
            const { tagGroups } = companySettings;
            const groups = [];
            groups.push(tagGroups.phase);
            groups.push(tagGroups.disziplin);
            groups.push(tagGroups.planart);
            groups.push(tagGroups.bauteil);
            groups.push(tagGroups.geschoss);
            groups.push(tagGroups.sonstiges);
            records.forEach((element) => {
                const currentGroup = groups.find((eGroup) => {
                    return eGroup.groupName === element.Gruppe;
                });
                if (currentGroup != null) {
                    const tmpTag = {
                        tagName: element.Tagname,
                        groupName: currentGroup.groupName,
                        color: currentGroup.color,
                    };
                    tagPromises.push(new AddCompanyTag({ tag: tmpTag }, company.id).execute(ClientStore.commandStore));
                }
            });
            await Promise.all(tagPromises);
        };
        this.generateExamples = async () => {
            const { data } = this.state;
            if (data != null) {
                return data.map((element) => {
                    return { Tagname: element.tagName, Gruppe: element.groupName };
                });
            }
            return [];
        };
        this.openFab = () => {
            if (Fab.instance == null) {
                window.setTimeout(this.openFab, 100);
                return;
            }
            const permitted = this.myRole() === 'admin';
            if (Platform.OS === 'web') {
                Fab.instance.open({
                    fabIcon: 'plus',
                    fabIconOpen: 'close',
                    small: false,
                    fabColor: ThemeManager.style.brandPrimary,
                    fabColorOpen: ThemeManager.style.brandSecondary,
                    onPressFab: !permitted
                        ? () => Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') })
                        : undefined,
                    fabActions: permitted
                        ? [
                            {
                                text: I18n.m.getMessage('tagsAddTitle'),
                                icon: 'plus',
                                onPress: this.openAddDialog,
                            },
                            {
                                text: I18n.m.getMessage('companyExcelDialogTitle'),
                                icon: 'microsoft-excel',
                                onPress: this.openExcelDialog,
                            },
                        ]
                        : undefined,
                });
            }
            else {
                Fab.instance.open({
                    fabIcon: 'plus',
                    fabIconOpen: 'close',
                    small: false,
                    fabColor: ThemeManager.style.brandPrimary,
                    fabColorOpen: ThemeManager.style.brandSecondary,
                    onPressFab: permitted
                        ? this.openAddDialog
                        : () => Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') }),
                });
            }
        };
        this.renderGroupCell = (item, column) => (<View key={`group_${item.id}`} style={[
                column.style,
                {
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    paddingHorizontal: 16,
                    alignItems: 'center',
                },
            ]}>
      <View style={{
                height: 16,
                width: 16,
                alignSelf: 'center',
                borderRadius: 8,
                backgroundColor: item.color,
                marginRight: 8,
            }}/>
      <MaterialText centeredBox>{`${item.groupName}`}</MaterialText>
    </View>);
        this.openAddDialog = (_e) => {
            const { companySettings } = this.props;
            Dialog.instance?.open({
                closeOnTouchOutside: false,
                content: <AddOrChangeTagDialog settings={companySettings}/>,
                contentPadding: false,
            });
        };
        this.openEditDialog = (tag) => (_e) => {
            if (this.myRole() === 'admin') {
                const { companySettings } = this.props;
                Dialog.instance?.open({
                    closeOnTouchOutside: false,
                    content: <AddOrChangeTagDialog tag={tag} settings={companySettings}/>,
                    contentPadding: false,
                });
            }
            else {
                Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') });
            }
        };
        this.state = {
            myRole: this.myRole(),
        };
    }
    componentDidUpdate(prevProps) {
        if (prevProps.companySettings !== this.props.companySettings ||
            prevProps.companySettings.tags !== this.props.companySettings.tags) {
            this.updateData();
        }
    }
    componentDidMount() {
        this.updateData();
        this.openFab();
    }
    updateData() {
        const { companySettings } = this.props;
        const data = [];
        if (companySettings != null && companySettings.tags != null) {
            companySettings.tags.forEach((tag) => {
                if (tag != null && tag.tagName != null && tag.tagName.length > 0) {
                    const id = `${tag.groupName}_${tag.tagName}`;
                    if (data.findIndex((t) => t.id === id) === -1)
                        data.push({ tag, id, ...tag });
                }
            });
        }
        this.setState({ data });
    }
    componentWillUnmount() {
        if (Fab.instance != null)
            Fab.instance.close();
    }
    render() {
        const { data, myRole } = this.state;
        if (!(myRole === 'admin' || myRole === 'user'))
            return null;
        const { size } = this.props;
        const tableMaxHeight = size.contentHeight - ThemeManager.style.headerHeight - ThemeManager.style.getScreenRelativePixelSize(68);
        return (<View style={{
                width: '100%',
                maxWidth: '100%',
                paddingTop: ThemeManager.style.contentPaddingValue,
                alignSelf: 'center',
                paddingHorizontal: 8,
            }}>
        <View style={{ flexDirection: 'row', marginLeft: 8 + ThemeManager.style.contentPaddingValue }}>
          <MaterialText centeredBox type={MaterialTextTypes.H6}>
            {I18n.m.getMessage('tags')}
          </MaterialText>
          <InfoButton text={I18n.m.getMessage('companySettingsCraftsTagsTypesInformation')}/>
        </View>
        <Card style={{ width: '100%', height: tableMaxHeight }}>
          <Table tableName="CompanyTags" actionItemsLength={2} actions={myRole === 'admin'
                ? (_item) => [
                    { icon: 'pencil-outline', onAction: this.openEditDialog, toolTip: I18n.m.getMessage('edit') },
                    { icon: 'delete', onAction: this.removeTag, toolTip: I18n.m.getMessage('delete') },
                ]
                : undefined} maxHeight={tableMaxHeight - 16} emptyTableImage={noTagsImg} emptyTableHint={I18n.m.getMessage('companyTagsNoDataHint')} emptyTableText={I18n.m.getMessage('companyTagsNoDataText')} data={data} sortBy="tagName" sortDirection="asc" columns={[
                {
                    title: I18n.m.getMessage('name'),
                    keyInData: 'tagName',
                    style: { width: 250 },
                    sortable: true,
                    dataType: 'string',
                },
                {
                    title: I18n.m.getMessage('tagGroup'),
                    cellRenderer: this.renderGroupCell,
                    keyInData: 'groupName',
                    dataType: 'string',
                    style: { width: 250 },
                },
            ]}/>
        </Card>
      </View>);
    }
}
