import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Card } from 'materialTheme/src/theme/components/Card';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Fab } from 'materialTheme/src/theme/components/Fab';
import { ExcelFileDialog } from 'materialTheme/src/theme/components/file/ExcelFileDialog';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { Platform, View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { AddCompanyFileType } from 'upmesh-core/src/client/commands/companies/filetypes/AddCompanyFileType';
import { RemoveCompanyFileType } from 'upmesh-core/src/client/commands/companies/filetypes/RemoveCompanyFileType';
import { InfoButton } from 'materialTheme/src/theme/components/InfoButton';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
import { AddFileTypeToCompanyDialog } from './AddFileTypeToCompanyDialog';
import { ChangeFileTypeCompanyDialog } from './ChangeFileTypeCompanyDialog';
const noCraftsImg = require('../../assets/img/no_types_or_crafts_or_tags.png');
export class CompanyFileTypeView extends PureComponent {
    constructor(props) {
        super(props);
        this.myRole = () => {
            return CompanyUserInfo.me == null ? 'user' : CompanyUserInfo.me.role;
        };
        this.removeFileType = (item) => (_e) => {
            Routing.instance.alert.post({
                text: I18n.m.getMessage('companyRemoveFileTypeQuestion', { fileType: item.fileType }),
                buttons: [
                    <ContainedButton key="no" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                    <ContainedButton key="yes" title={I18n.m.getMessage('delete')} onPress={this.removeFileTypeNow(item.fileType)} backgroundColor={ThemeManager.style.brandDanger}/>,
                ],
            });
        };
        this.removeFileTypeNow = (fileType) => (_e) => {
            const { company } = this.props;
            LoadingEvents.instance.startLoading();
            Alert.instance?.close(() => {
                const remove = new RemoveCompanyFileType({ type: fileType }, company.id, CurrentUser.token);
                remove
                    .execute(ClientStore.commandStore)
                    .catch((e) => {
                    console.error(e);
                    Routing.instance.alert.post({ text: DefaultErrorHandler.getDefaultErrorMessages(e, I18n.m) });
                })
                    .finally(() => {
                    LoadingEvents.instance.stopLoading();
                });
            });
        };
        this.openFab = () => {
            if (Fab.instance == null) {
                window.setTimeout(this.openFab, 100);
                return;
            }
            const permitted = this.myRole() === 'admin';
            if (Platform.OS === 'web') {
                Fab.instance.open({
                    fabIcon: 'plus',
                    fabIconOpen: 'close',
                    small: false,
                    fabColor: ThemeManager.style.brandPrimary,
                    fabColorOpen: ThemeManager.style.brandSecondary,
                    onPressFab: !permitted
                        ? () => Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') })
                        : undefined,
                    fabActions: permitted
                        ? [
                            {
                                text: I18n.m.getMessage('companyAddFileTypeDialogTitle'),
                                icon: 'plus',
                                onPress: this.openAddDialog,
                            },
                            {
                                text: I18n.m.getMessage('companyExcelDialogTitle'),
                                icon: 'microsoft-excel',
                                onPress: this.openExcelDialog,
                            },
                        ]
                        : undefined,
                });
            }
            else {
                Fab.instance.open({
                    fabIcon: 'plus',
                    fabIconOpen: 'close',
                    small: false,
                    fabColor: ThemeManager.style.brandPrimary,
                    fabColorOpen: ThemeManager.style.brandSecondary,
                    onPressFab: permitted
                        ? this.openAddDialog
                        : () => Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') }),
                });
            }
        };
        this.openExcelDialog = () => {
            Dialog.instance?.open({
                closeOnTouchOutside: false,
                fullscreenResponsive: true,
                contentPadding: false,
                scrollable: false,
                showCloseButton: false,
                width: 1042,
                content: (<ExcelFileDialog saveFunction={this.saveFunction} generateExampleRecords={this.generateExamples} columns={[
                        {
                            title: I18n.m.getMessage('companyExcelCategoryNameHeader'),
                            keyInData: 'Kategorie',
                            style: { width: 200 },
                        },
                    ]}/>),
            });
        };
        this.generateExamples = async () => {
            const { data } = this.state;
            if (data != null) {
                return data.map((element) => {
                    return { Kategorie: element.fileType };
                });
            }
            return [];
        };
        this.saveFunction = async (records) => {
            const { company } = this.props;
            const fileTypePromises = [];
            records.forEach((element) => {
                fileTypePromises.push(new AddCompanyFileType({ type: element.Kategorie }, company.id).execute(ClientStore.commandStore));
            });
            await Promise.all(fileTypePromises);
        };
        this.openAddDialog = (_e) => {
            const { company } = this.props;
            Dialog.instance?.open({
                closeOnTouchOutside: false,
                content: <AddFileTypeToCompanyDialog company={company}/>,
                showCloseIcon: true,
                contentPadding: false,
                scrollable: false,
            });
        };
        this.openEditDialog = (item) => (_e) => {
            if (this.myRole() === 'admin') {
                const { company } = this.props;
                Dialog.instance?.open({
                    closeOnTouchOutside: false,
                    content: <ChangeFileTypeCompanyDialog type={item.fileType} company={company}/>,
                    showCloseIcon: true,
                    contentPadding: false,
                    scrollable: true,
                });
            }
            else {
                Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') });
            }
        };
        this.state = {
            myRole: this.myRole(),
        };
    }
    componentDidUpdate(prevProps) {
        if (prevProps.companySettings !== this.props.companySettings ||
            prevProps.companySettings.fileTypes !== this.props.companySettings.fileTypes) {
            this.updateData();
        }
    }
    componentDidMount() {
        this.updateData();
        this.openFab();
    }
    updateData() {
        const { companySettings } = this.props;
        const data = [];
        if (companySettings != null && companySettings.fileTypes != null) {
            companySettings.fileTypes.forEach((fileType) => {
                data.push({ fileType, id: fileType });
            });
        }
        this.setState({ data });
    }
    componentWillUnmount() {
        if (Fab.instance != null)
            Fab.instance.close();
    }
    render() {
        const { data, myRole } = this.state;
        if (!(myRole === 'admin' || myRole === 'user'))
            return null;
        const { size } = this.props;
        const tableMaxHeight = size.contentHeight - ThemeManager.style.headerHeight - ThemeManager.style.getScreenRelativePixelSize(68);
        return (<View style={{
                width: '100%',
                maxWidth: '100%',
                paddingTop: ThemeManager.style.contentPaddingValue,
                alignSelf: 'center',
                paddingHorizontal: 8,
            }}>
        <View style={{ flexDirection: 'row', marginLeft: 8 + ThemeManager.style.contentPaddingValue }}>
          <MaterialText centeredBox type={MaterialTextTypes.H6}>
            {I18n.m.getMessage('fileTypes')}
          </MaterialText>
          <InfoButton text={I18n.m.getMessage('companySettingsCraftsTagsTypesInformation')}/>
        </View>
        <Card style={{ width: '100%', height: tableMaxHeight }}>
          <Table tableName="CompanyFileTypes" actionItemsLength={2} actions={myRole === 'admin'
                ? (_item) => [
                    { icon: 'pencil-outline', onAction: this.openEditDialog, toolTip: I18n.m.getMessage('edit') },
                    { icon: 'delete', onAction: this.removeFileType, toolTip: I18n.m.getMessage('delete') },
                ]
                : undefined} maxHeight={tableMaxHeight - 16} emptyTableImage={noCraftsImg} emptyTableHint={I18n.m.getMessage('companyFileTypeNoDataHint')} emptyTableText={I18n.m.getMessage('companyFileTypeNoDataText')} data={data} sortBy="fileType" sortDirection="asc" columns={[
                {
                    title: I18n.m.getMessage('name'),
                    keyInData: 'fileType',
                    style: { width: 500 },
                    sortable: true,
                    dataType: 'string',
                },
            ]}/>
        </Card>
      </View>);
    }
}
