import { PDFOverlay } from 'upmesh-core/src/pdf/PDFOverlay';
import { TicketWithPlanDownloaderBase } from './TicketWithPlanDownloaderBase';
export class TicketWithPlanDownloader extends TicketWithPlanDownloaderBase {
    async open(includeTicketInPlanDownload) {
        const token = await this.getToken();
        if (token.length > 0) {
            const pdf = await fetch(`${this.getUrl()}/${token}`);
            if (pdf.status === 200) {
                const bytes = await pdf.arrayBuffer();
                const overlayer = new PDFOverlay(bytes, this.planVersion);
                if (includeTicketInPlanDownload) {
                    await overlayer.addTickets(this.tickets);
                }
                if (this.project?.showPlanQrCode) {
                    await overlayer.addQrCode(this.project, this.planVersion, this.config);
                }
                const byteResult = await overlayer.finalize();
                const blob = new Blob([byteResult], { type: 'application/pdf' });
                const downloadLink = document.createElement('a');
                downloadLink.href = URL.createObjectURL(blob);
                downloadLink.download = this.getFileName();
                downloadLink.click();
            }
        }
    }
}
