import { Icon } from 'materialTheme/src/theme/components/Icon';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React from 'react';
import { I18n } from '../../i18n/I18n';
import { openTagFilter } from './ticketTagFilterUtil';
export function TicketTagFilterIcon(props) {
    const isFilterActive = props.currentFilter.t != null && props.currentFilter.t.length > 0;
    return (<Icon badge={props.currentFilter.t != null && props.currentFilter.t.length > 0
            ? props.currentFilter.t?.length.toString(10)
            : undefined} key={`tags_${isFilterActive}`} icon="tag-multiple-outline" toolTip={props.currentFilter.t != null && props.currentFilter.t.length > 0
            ? `${I18n.m.getMessage('tags')}: ${props.currentFilter.t.join(', ')}`
            : I18n.m.getMessage('tags')} onPress={openTagFilter(props.currentFilter, props.tickets, props.setFilter)} color={isFilterActive
            ? ThemeManager.style.brandPrimary
            : props.color
                ? props.color
                : ThemeManager.style.defaultIconColor}/>);
}
