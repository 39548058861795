import { ViewEntity } from 'upmesh-core/src/client/query/entities/ViewEntity';
import { RemoveView } from 'upmesh-core/src/client/commands/project/views/RemoveView';
import { CreateView } from 'upmesh-core/src/client/commands/project/views/CreateView';
import { ChangeView } from 'upmesh-core/src/client/commands/project/views/ChangeView';
import { Measurement } from 'materialTheme/src/theme/components/utils/Measurement';
import { Menu } from 'materialTheme/src/theme/components/Menu';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import React from 'react';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { PublishView } from 'upmesh-core/src/client/commands/project/views/PublishView';
import { RightsManager } from 'upmesh-core/src/access/rights/RightsManager';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { I18n } from '../../i18n/I18n';
import { CurrentProject } from '../project/CurrentProject';
export const getMainView = () => new ViewEntity({
    id: 'main',
    createdBy: 'main',
    name: I18n.m.getMessage('ticketsViewMainView'),
    isPublic: false,
    projectId: 'main',
    additionalData: '',
    default: false,
    type: 'table',
    on: 'tickets',
    filter: '',
});
export const deleteView = (id) => {
    const c = new RemoveView({}, id);
    c.execute().catch(console.error);
};
export const duplicateView = (view) => {
    const c = new CreateView({
        name: view.name,
        on: view.on,
        type: view.type,
        filter: view.filter,
        projectId: view.projectId,
    });
    c.execute().catch(console.error);
};
export const renameView = (id, title) => {
    const c = new ChangeView({ name: title }, id);
    c.execute().catch(console.error);
};
export const setAsDefault = async (id) => {
    const { userId } = CurrentUser;
    const projectId = CurrentProject.instance.getCurrentProjectId();
    const currentDefault = await UpmeshClient.instance.modals.view.get({
        filter: `createdBy eq '${userId}' and projectId eq '${projectId}' and default eq true`,
    });
    for (const item of currentDefault) {
        const command = new ChangeView({ default: false }, item.id);
        await command.execute();
    }
    const c = new ChangeView({ default: true }, id);
    c.execute().catch(console.error);
};
export const saveAdditionalData = (id, additionalData) => {
    const dataString = JSON.stringify(additionalData);
    const c = new ChangeView({ additionalData: dataString }, id);
    c.execute().catch(console.error);
};
export const publishView = (id, isPublic) => {
    const c = new PublishView({ isPublic }, id);
    c.execute().catch(console.error);
};
export const onPressViewOptions = (view, enableRenaming, additionalData, viewDataHasChanged) => (e) => {
    const { id } = view;
    const asyncNow = async (e) => {
        const measurement = await Measurement.measure(e.currentTarget);
        const client = {
            height: measurement.height,
            width: measurement.width,
            x: measurement.pageX,
            y: measurement.pageY,
        };
        const items = [
            {
                onPress: () => {
                    Menu.instance?.close();
                    enableRenaming();
                },
                thumbnail: {
                    thumbnail: <Icon icon="pencil-outline" toolTip=""/>,
                    width: 40,
                },
                text: I18n.m.getMessage('ticketsViewRename'),
            },
            {
                onPress: () => {
                    Menu.instance?.close();
                    setAsDefault(view.id).catch(console.error);
                },
                thumbnail: {
                    thumbnail: <Icon icon="pin-outline" toolTip=""/>,
                    width: 40,
                },
                text: I18n.m.getMessage('ticketsViewSetDefault'),
            },
            {
                onPress: () => {
                    Menu.instance?.close();
                    duplicateView(view);
                },
                thumbnail: {
                    thumbnail: <Icon icon="content-duplicate" toolTip=""/>,
                    width: 40,
                },
                text: I18n.m.getMessage('ticketsViewDuplicate'),
            },
            {
                onPress: () => {
                    Menu.instance?.close();
                    deleteView(id);
                },
                thumbnail: {
                    thumbnail: <Icon icon="delete-outline" toolTip=""/>,
                    width: 40,
                },
                text: I18n.m.getMessage('ticketsViewDelete'),
            },
            {
                onPress: () => {
                    Menu.instance?.close();
                    saveAdditionalData(id, additionalData);
                },
                thumbnail: {
                    thumbnail: (<Icon icon="autorenew" toolTip="" color={viewDataHasChanged ? ThemeManager.style.defaultIconColor : ThemeManager.style.disabledColor}/>),
                    width: 40,
                },
                text: I18n.m.getMessage('ticketsViewOverride'),
                disabled: !viewDataHasChanged,
            },
        ];
        const { userId } = CurrentUser;
        const canPublish = (await RightsManager.hasWriteRight(view.projectId, userId, 'commandChangeProjectTemplateStatus')) &&
            view.createdBy === userId;
        const isOwnPublicView = view.isPublic;
        if (canPublish || isOwnPublicView) {
            items.push({
                onPress: () => {
                    Menu.instance?.close();
                    publishView(view.id, !view.isPublic);
                },
                thumbnail: {
                    thumbnail: <Icon icon={view.isPublic ? 'publish-off' : 'publish'} toolTip=""/>,
                    width: 40,
                },
                text: view.isPublic ? I18n.m.getMessage('ticketsViewRevokePublish') : I18n.m.getMessage('ticketsViewPublish'),
            });
        }
        Menu.instance?.open({
            client,
            items,
            disabledTextColor: ThemeManager.style.disabledColor,
        });
    };
    asyncNow(e).catch(console.error);
};
export const sortTicketViews = (views) => {
    const { userId } = CurrentUser;
    const sorted = [...views].filter((v) => v.id !== 'main');
    sorted.sort((a, b) => {
        const aIsOtherPublic = a.isPublic && a.createdBy !== userId;
        const bIsOtherPublic = b.isPublic && b.createdBy !== userId;
        if (aIsOtherPublic && !bIsOtherPublic) {
            return 1;
        }
        if (!aIsOtherPublic && bIsOtherPublic) {
            return -1;
        }
        if (a.default && !b.default) {
            return -1;
        }
        if (!a.default && b.default) {
            return 1;
        }
        return 0;
    });
    const result = [];
    const hasDefault = sorted[0].default;
    if (hasDefault)
        result.push(sorted[0]);
    result.push(getMainView());
    if (hasDefault)
        result.push(...sorted.slice(1));
    else
        result.push(...sorted);
    return result;
};
export const createNewView = (type) => {
    Menu.instance?.close();
    let name = I18n.m.getMessage('ticketsViewCanban');
    if (type === 'list')
        name = I18n.m.getMessage('ticketsViewList');
    if (type === 'table')
        name = I18n.m.getMessage('ticketsViewTable');
    if (type === 'calendar')
        name = I18n.m.getMessage('ticketsViewCalendar');
    const c = new CreateView({
        type,
        name,
        filter: '',
        on: 'tickets',
        projectId: CurrentProject.instance.getCurrentProjectId() ?? '',
    });
    c.execute().catch(console.error);
};
export const onPressNewView = (e) => {
    const client = {
        height: 0,
        width: 0,
        x: e.nativeEvent.pageX,
        y: e.nativeEvent.pageY,
    };
    const items = [
        {
            onPress: () => createNewView('canban'),
            thumbnail: {
                thumbnail: <Icon icon="poll" toolTip=""/>,
                width: 40,
            },
            text: I18n.m.getMessage('ticketsViewCanban'),
        },
        {
            onPress: () => createNewView('list'),
            thumbnail: {
                thumbnail: <Icon icon="format-list-bulleted-square" toolTip=""/>,
                width: 40,
            },
            text: I18n.m.getMessage('ticketsViewList'),
        },
        {
            onPress: () => createNewView('table'),
            thumbnail: {
                thumbnail: <Icon icon="table-large" toolTip=""/>,
                width: 40,
            },
            text: I18n.m.getMessage('ticketsViewTable'),
        },
        {
            onPress: () => createNewView('calendar'),
            thumbnail: {
                thumbnail: <Icon icon="calendar" toolTip=""/>,
                width: 40,
            },
            text: I18n.m.getMessage('ticketsViewCalendar'),
        },
    ];
    Menu.instance?.open({
        client,
        items,
    });
};
export const getIconNameFromViewType = (type) => {
    if (type === 'canban')
        return 'poll';
    if (type === 'calendar')
        return 'calendar';
    if (type === 'table')
        return 'table-large';
    return 'format-list-bulleted-square';
};
