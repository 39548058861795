import { ChipDialogForm, } from 'materialTheme/src/theme/components/chips/ChipDialogForm';
import React from 'react';
import { View } from 'react-native';
import { I18n } from '../../../i18n/I18n';
import { CurrentProject } from '../../project/CurrentProject';
export function BulkChangeDialogList(props) {
    const getTagList = () => {
        const projectTags = [];
        const projectTagGroups = [];
        const project = CurrentProject.instance.getCurrentProject();
        if (project != null) {
            project.tags?.forEach((t) => {
                projectTags.push({
                    id: t.tagName,
                    groupId: t.groupName,
                    secondTextLine: t.groupName,
                    title: t.tagName,
                    color: t.color,
                    thumbnail: {
                        width: 24,
                        rounded: true,
                        thumbnail: <View style={{ height: 24, width: 24, borderRadius: 12, backgroundColor: t.color }}/>,
                    },
                });
            });
            const projectTagGroups = [];
            if (project.tagGroups != null)
                for (const key in project.tagGroups) {
                    projectTagGroups.push({
                        backgroundColor: project.tagGroups[key].color,
                        groupName: project.tagGroups[key].groupName,
                        id: project.tagGroups[key].groupName,
                    });
                }
        }
        projectTags.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
        return { tags: projectTags, groups: projectTagGroups };
    };
    const getTicketTypeList = () => {
        const typesList = [
            {
                id: '0',
                title: I18n.m.getMessage('ticketTypeNotSelected'),
                onPress: () => props.onSelectSingleOption('0'),
            },
        ];
        const project = CurrentProject.instance.getCurrentProject();
        if (project != null && project.ticketTypes != null) {
            const types = project.ticketTypes;
            types.forEach((t) => typesList.push({
                id: t,
                title: t,
                onPress: () => props.onSelectSingleOption(t),
            }));
        }
        return typesList;
    };
    const getMultiselect = () => {
        const { changedTopic } = props;
        if (changedTopic === 'type')
            return false;
        if (typeof changedTopic !== 'string' && changedTopic.customField && changedTopic.customField.type === 'List')
            return false;
        return true;
    };
    const getTitle = () => {
        const { changedTopic } = props;
        let title = '';
        if (changedTopic === 'tags')
            title = I18n.m.getMessage('bulkChangesAddTags');
        else if (changedTopic === 'removeTags')
            title = I18n.m.getMessage('bulkChangesRemoveTags');
        else if (changedTopic === 'type')
            title = I18n.m.getMessage('bulkChangesChangeType');
        else
            title = changedTopic.label;
        return title;
    };
    const getCustomFieldList = (changedTopic) => {
        const items = [];
        if (changedTopic.customField != null) {
            const multiselect = getMultiselect();
            if (!multiselect) {
                const { list } = changedTopic.customField.options;
                if (list && list.length > 0) {
                    list.forEach((i) => {
                        items.push({
                            title: i,
                            onPress: () => props.onSelectSingleOption(i),
                        });
                    });
                }
            }
            else {
                const { list } = changedTopic.customField.options;
                if (list && list.length > 0) {
                    list.forEach((i) => {
                        items.push({ title: i });
                    });
                }
            }
        }
        return items;
    };
    const renderList = () => {
        const { changedTopic, onCancel, onSave } = props;
        let items = [];
        let groups = [];
        if (changedTopic === 'type')
            items = getTicketTypeList();
        else if (changedTopic === 'tags' || changedTopic === 'removeTags') {
            const tagLists = getTagList();
            items = tagLists.tags;
            groups = tagLists.groups;
        }
        else if (typeof changedTopic !== 'string')
            items = getCustomFieldList(changedTopic);
        const multiselect = getMultiselect();
        return (<ChipDialogForm title={getTitle()} items={items} multiselect={multiselect} showSelectedIcons={multiselect} onSave={(selected) => onSave(selected)} onCancel={onCancel} chipGroups={groups} sortByGroup={changedTopic === 'tags' || changedTopic === 'removeTags'} disableAutoSort={changedTopic === 'type'}/>);
    };
    return renderList();
}
