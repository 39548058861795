import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Fab } from 'materialTheme/src/theme/components/Fab';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Menu } from 'materialTheme/src/theme/components/Menu';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { Switch } from 'materialTheme/src/theme/components/Switch';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { ScrollView, UIManager, View } from 'react-native';
import { ChangeRoleRight } from 'upmesh-core/src/client/commands/roles/ChangeRoleRight';
import { DeleteRole } from 'upmesh-core/src/client/commands/roles/DeleteRole';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
import { ChangeRoleDialog } from './ChangeRoleDialog';
import { CreateRoleDialog } from './CreateRoleDialog';
export class TeamRolesList extends PureComponent {
    constructor(props) {
        super(props);
        this.updateRoles = (en) => {
            const { project } = this.props;
            en.entities.forEach((e) => {
                if (e.entity?.projectId === project?.id) {
                    this.init()
                        .then((roles) => {
                        this.setState({ roles }, this.onLayout);
                    })
                        .catch((err) => console.debug('cant load Roles', err));
                }
            });
        };
        this.readKeys = ['plans', 'allTickets', 'logs', 'teams', 'projectSettings'];
        this.writeKeys = new Map(Object.entries({
            plans: [
                'commandCreatePlan',
                'commandCreatePlanFile',
                'commandCreatePlanVersion',
                'commandActivatePlanVersion',
                'commandChangePlanDetails',
                'commandDiscardPlanFile',
            ],
            tickets: [
                'commandCreateTicket',
                'canChangeAllTickets',
                'commandAssignTicketTo',
                'commandChangeTicketApprover',
                'commandChangeTicketDescription',
                'commandChangeTicketStatus',
                'commandChangeTicketTags',
                'commandChangeTicketTitle',
                'commandChangeTicketType',
                'commandAddTicketWatcher',
                'commandRemoveTicketWatcher',
                'commandSetTicketShouldCompleteDate',
                'commandArchiveTicket',
                'commandDeleteTicket',
                'commandRestoreTicket',
                'commandUnArchiveTicket',
                'commandChangeTicketPlanAffiliation',
                'commandChangeTicketPlanPosition',
                'commandChangeTicketCraft',
                'commandChangeTicketSubTicketFrom',
            ],
            team: ['commandInviteUser', 'commandRemoveUser', 'commandCreateProjectRole'],
            files: ['commandEditFolders'],
            project: [
                'commandChangeProject',
                'commandChangeProjectTicketLayouts',
                'commandChangeProjectCrafts',
                'commandChangeProjectTags',
                'commandChangeProjectTicketTypes',
                'commandChangeProjectAddress',
                'commandPublishViews',
            ],
        }));
        this.init = async () => {
            const { project } = this.props;
            if (project == null)
                return [];
            const baseRoles = await UpmeshClient.instance.modals.projectRoles.get({
                filter: `projectId eq '0'`,
                orderby: 'id ASC',
            });
            const roles = await UpmeshClient.instance.modals.projectRoles.get({
                filter: `projectId eq '${project.id}' and deleted ne true`,
                orderby: 'createdAt ASC',
            });
            roles.forEach((role) => {
                if (role.basedOnRoleId != null) {
                    const baseIndex = baseRoles.findIndex((a) => a.id === role.basedOnRoleId);
                    if (baseIndex >= 0)
                        role.baseRole = baseRoles[baseIndex];
                }
            });
            const sort = ['1', '3', '2', '4'];
            baseRoles.sort((a, b) => {
                const ai = sort.indexOf(a.id);
                const bi = sort.indexOf(b.id);
                return ai - bi;
            });
            return [...baseRoles, ...roles];
        };
        this.onLayout = (e) => {
            if (e != null && e.nativeEvent != null && e.nativeEvent.layout != null) {
                const { layout } = e.nativeEvent;
                this.lastLayout = layout;
            }
            if (this.lastLayout != null) {
                const targetWidth = Math.min(this.targetColumnWidth, ResizeEvent.current.windowWidth / 2);
                const layout = this.lastLayout;
                const { roles, start } = this.state;
                const max = layout.width - this.targetFirstColumnWidth;
                const maxDisplay = Math.min(Math.max(1, Math.floor(max / targetWidth)), roles.length);
                const columnWidth = targetWidth;
                const firstColumnWidth = layout.width - maxDisplay * columnWidth;
                let newStart = start;
                if (start + maxDisplay > roles.length)
                    newStart = Math.max(0, roles.length - maxDisplay);
                this.setState({ displays: maxDisplay, columnWidth, start: newStart, firstColumnWidth, layoutKnown: true });
            }
        };
        this.renameRole = (r) => (_e) => {
            const { project } = this.props;
            Menu.instance?.close();
            if (project == null)
                return;
            ChangeRoleDialog.open(project, r);
        };
        this.removeRole = (r) => (_e) => {
            Menu.instance?.close();
            Routing.instance.alert.post({
                title: I18n.m.getMessage('roleDeleteQuestionTitle', { roleName: r.roleName }),
                text: I18n.m.getMessage('roleDeleteQuestionText'),
                buttons: [
                    <ContainedButton key="cancel" onPress={Alert.instance?.close} title={I18n.m.getMessage('cancel')}/>,
                    <ContainedButton key="del" onPress={this.removeRoleNow(r)} title={I18n.m.getMessage('delete')} backgroundColor={ThemeManager.style.brandDanger}/>,
                ],
            });
        };
        this.removeRoleNow = (r) => (_e) => {
            Alert.instance?.close();
            const c = new DeleteRole({}, r.id);
            c.execute().catch((e) => DefaultErrorHandler.showDefaultErrorAlert(e));
        };
        this.targetFirstColumnWidth = 320;
        this.firstRowHeight = 80;
        this.targetColumnWidth = 220;
        this.columnHeight = 56;
        this.showNext = () => {
            this.setState((prevState) => {
                return { start: Math.min(prevState.roles.length - prevState.displays, prevState.start + prevState.displays) };
            });
        };
        this.showPrev = () => {
            this.setState((prevState) => {
                return { start: Math.max(0, prevState.start - prevState.displays) };
            });
        };
        this.openRoleMenu = (r) => (e) => {
            UIManager.measureInWindow(e.nativeEvent.target, (x, y, _width, height) => {
                const client = {
                    x,
                    y,
                    height,
                    width: 256,
                };
                Menu.instance?.open({
                    client,
                    items: [
                        {
                            thumbnail: { thumbnail: <Icon toolTip="" icon="pencil-outline"/>, width: 24 },
                            text: I18n.m.getMessage('edit'),
                            onPress: this.renameRole(r),
                        },
                        {
                            thumbnail: { thumbnail: <Icon toolTip="" icon="delete"/>, width: 24 },
                            text: I18n.m.getMessage('delete'),
                            onPress: this.removeRole(r),
                        },
                    ],
                });
            });
        };
        this.onChangeRight = (p, k) => (value) => {
            const c = new ChangeRoleRight({ right: k, value }, p.id);
            c.execute().catch((e) => DefaultErrorHandler.showDefaultErrorAlert(e));
        };
        this.createRole = () => {
            const { project } = this.props;
            if (project == null)
                return;
            CreateRoleDialog.open(project);
        };
        this.openFab = () => {
            const { canEdit, size } = this.props;
            if (canEdit && Fab.instance != null) {
                if (CompanyUserInfo.company != null) {
                    const sViewHeight = size.windowWidth <= ThemeManager.style.breakpointM ? 48 : 0;
                    Fab.instance.open({
                        extraPaddingBottom: sViewHeight,
                        fabIcon: 'plus',
                        fabIconOpen: 'close',
                        small: false,
                        fabColor: ThemeManager.style.brandPrimary,
                        fabColorOpen: ThemeManager.style.brandSecondary,
                        onPressFab: this.createRole,
                    });
                }
            }
            else {
                window.setTimeout(this.openFab, 100);
            }
        };
        this.state = {
            roles: [],
            displays: 1,
            start: 0,
            columnWidth: this.targetColumnWidth,
            firstColumnWidth: this.targetFirstColumnWidth,
            layoutKnown: false,
        };
    }
    componentDidMount() {
        const { project } = this.props;
        if (project != null) {
            this.openFab();
        }
        this.init()
            .then((roles) => {
            this.setState({ roles });
        })
            .catch((err) => console.debug('cant load Roles', err));
        UpmeshClient.eventDispatcher.attach({
            readModelName: 'ProjectRoles',
            attachKey: 'TeamRoles',
            callback: this.updateRoles,
        });
    }
    componentWillUnmount() {
        if (Fab.instance != null) {
            Fab.instance.close();
        }
        UpmeshClient.eventDispatcher.detach('ProjectRoles', 'TeamRoles');
    }
    render() {
        const { maxHeight, size } = this.props;
        const { roles, columnWidth, displays, start, firstColumnWidth, layoutKnown } = this.state;
        if (roles.length === 0)
            return <Spinner />;
        let writeSize = 0;
        this.writeKeys.forEach((f) => {
            writeSize += f.length + 1;
        });
        const contentHeight = (this.readKeys.length + writeSize) * this.columnHeight + 72;
        const hasNext = start + displays < roles.length;
        const hasPrev = start > 0;
        if (!layoutKnown)
            return (<View key="roleLayout" style={{ maxWidth: '100%', width: size.contentWidth, height: maxHeight, position: 'relative' }} onLayout={this.onLayout}>
          <Spinner />
        </View>);
        return (<View key="roleLayout" style={{
                maxWidth: '100%',
                width: size.contentWidth,
                height: maxHeight,
                position: 'relative',
            }} onLayout={this.onLayout}>
        <View style={{
                position: 'absolute',
                width: firstColumnWidth,
                top: 0,
                left: 0,
                height: this.firstRowHeight,
                flexDirection: 'row',
                borderWidth: 0,
                borderBottomWidth: ThemeManager.style.borderWidth,
                borderStyle: 'solid',
                borderColor: ThemeManager.style.borderColor,
                paddingLeft: 8,
            }}>
          <MaterialText centeredBox strong>
            {I18n.m.getMessage('rolesActions')}
          </MaterialText>
        </View>
        <View style={{
                position: 'absolute',
                width: 'auto',
                top: 0,
                left: firstColumnWidth,
                height: this.firstRowHeight,
                flexDirection: 'row',
            }}>
          {this.renderHeader()}
        </View>
        {hasPrev ? (<View style={{
                    position: 'absolute',
                    width: 36,
                    top: (this.firstRowHeight - 36) / 2,
                    left: firstColumnWidth,
                    height: 36,
                    flexDirection: 'row',
                }}>
            <Icon icon="chevron-left" toolTip={I18n.m.getMessage('back')} onPress={this.showPrev}/>
          </View>) : null}
        {hasNext ? (<View style={{
                    position: 'absolute',
                    width: 36,
                    top: (this.firstRowHeight - 36) / 2,
                    right: 0,
                    height: 36,
                    flexDirection: 'row',
                }}>
            <Icon icon="chevron-right" toolTip={I18n.m.getMessage('next')} onPress={this.showNext}/>
          </View>) : null}

        <ScrollView style={{
                width: '100%',
                backgroundColor: 'transparent',
                top: this.firstRowHeight,
                position: 'absolute',
                height: maxHeight - this.firstRowHeight,
            }} contentContainerStyle={{
                backgroundColor: 'transparent',
                width: '100%',
                height: contentHeight,
                position: 'relative',
            }}>
          <View style={{
                position: 'absolute',
                backgroundColor: 'transparent',
                width: firstColumnWidth,
                top: 0,
                left: 0,
                paddingTop: 0,
            }}>
            <View style={{
                width: firstColumnWidth,
                borderWidth: 0,
                borderBottomWidth: ThemeManager.style.borderWidth,
                borderRightWidth: ThemeManager.style.borderWidth,
                borderStyle: 'solid',
                borderColor: ThemeManager.style.borderColor,
            }}>
              <View style={{ paddingLeft: 8, paddingTop: 8, height: 36 }}>
                <MaterialText color={ThemeManager.style.black54} strong>
                  {I18n.m.getMessage('rolesReadRights')}
                </MaterialText>
              </View>
              {this.renderLeftColumns('description', this.readKeys, 'rolesReadRight_')}
            </View>
            <View style={{
                width: firstColumnWidth,
                borderWidth: 0,
                borderBottomWidth: ThemeManager.style.borderWidth,
                borderRightWidth: ThemeManager.style.borderWidth,
                borderStyle: 'solid',
                borderColor: ThemeManager.style.borderColor,
            }}>
              <View style={{ paddingLeft: 8, paddingTop: 8, height: 36 }}>
                <MaterialText color={ThemeManager.style.black54} strong>
                  {I18n.m.getMessage('rolesWriteRights')}
                </MaterialText>
              </View>
              {this.renderLeftColumn('description', this.writeKeys)}
            </View>
          </View>

          <View style={{
                position: 'absolute',
                left: firstColumnWidth,
                width: columnWidth * roles.length,
                backgroundColor: 'transparent',
                height: contentHeight,
            }}>
            <View style={{ position: 'absolute', width: 'auto', top: 0, left: 0, flexDirection: 'row' }}>
              {this.renderContentColumn()}
            </View>
          </View>
        </ScrollView>
      </View>);
    }
    renderHeader() {
        const { roles, start, displays, columnWidth } = this.state;
        const head = [];
        for (let i = start; i < Math.min(start + displays, roles.length); i += 1) {
            const r = roles[i];
            let freeRole = r.id === '2' || r.id === '4';
            const alertContent = freeRole
                ? I18n.m.getMessage('teamRolesFreeTooltip')
                : I18n.m.getMessage('teamRolesPaidOnlyTooltip');
            let content = (<View style={{
                    width: '100%',
                    justifyContent: 'center',
                    height: this.firstRowHeight,
                    position: 'relative',
                    alignItems: 'center',
                }}>
          <Icon toolTip={freeRole ? I18n.m.getMessage('teamRolesFreeTooltip') : I18n.m.getMessage('teamRolesPaidOnlyTooltip')} icon={freeRole ? 'currency-eur-off' : 'currency-eur'} outerSize={24} backgroundColor={ThemeManager.style.appBgColorDarken} onPress={() => Alert.instance?.open({
                    content: alertContent,
                    closeOnTouchOutside: true,
                    buttons: [
                        <ContainedButton title={I18n.m.getMessage('ok')} onPress={Alert.instance?.close} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
                    ],
                })}/>
          <MaterialText textAlign="center" numberOfLines={1} centeredBox strong fixedWidth="100%" centeredText>
            {I18n.m.getMessage(r.roleName)}
          </MaterialText>
          {r.roleName === 'subcontractor' ? (<MaterialText textAlign="center" numberOfLines={1} centeredBox strong fixedWidth="100%" centeredText>
              {I18n.m.getMessage('subcontractorInOwnCompany')}
            </MaterialText>) : null}
          <View style={{
                    position: 'absolute',
                    right: 0,
                    bottom: (this.firstRowHeight - 36) / 2,
                    top: (this.firstRowHeight - 36) / 2,
                    width: 36,
                    alignItems: 'center',
                }}/>
        </View>);
            if (r.baseRole != null) {
                const { baseRole, basedOnRoleId } = r;
                freeRole = basedOnRoleId === '2';
                content = (<View style={{
                        width: '100%',
                        justifyContent: 'center',
                        height: this.firstRowHeight,
                        position: 'relative',
                        alignItems: 'center',
                    }}>
            <Icon toolTip={freeRole ? I18n.m.getMessage('teamRolesFreeTooltip') : I18n.m.getMessage('teamRolesPaidOnlyTooltip')} icon={freeRole ? 'currency-eur-off' : 'currency-eur'} backgroundColor={ThemeManager.style.appBgColorDarken} outerSize={24} onPress={() => Alert.instance?.open({
                        content: `${alertContent}\n\n${I18n.m.getMessage('teamRolesBasedOn')}${I18n.m.getMessage(baseRole.roleName)}`,
                        closeOnTouchOutside: true,
                        buttons: [
                            <ContainedButton title={I18n.m.getMessage('ok')} onPress={Alert.instance?.close} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
                        ],
                    })}/>
            <MaterialText centeredBox strong fixedWidth="100%" centeredText>
              {I18n.m.getMessage(r.roleName)}
            </MaterialText>
            <View style={{
                        position: 'absolute',
                        right: 0,
                        bottom: (this.firstRowHeight - 36) / 2,
                        top: (this.firstRowHeight - 36) / 2,
                        width: 36,
                        alignItems: 'center',
                    }}>
              <Icon toolTip="" icon="dots-vertical" onPress={this.openRoleMenu(r)}/>
            </View>
          </View>);
            }
            head.push(<View key={`role_${r.id}`} style={{
                    height: this.firstRowHeight,
                    borderWidth: 0,
                    width: columnWidth,
                    borderBottomWidth: ThemeManager.style.borderWidth,
                    borderStyle: 'solid',
                    borderColor: ThemeManager.style.borderColor,
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingHorizontal: 20,
                }}>
          {content}
        </View>);
        }
        return <View style={{ flexDirection: 'row' }}>{head}</View>;
    }
    renderContentColumn() {
        const { roles, start, displays, columnWidth } = this.state;
        const head = [];
        for (let i = start; i < Math.min(start + displays, roles.length); i += 1) {
            const r = roles[i];
            head.push(<View key={`roleRights_${r.id}`} style={{
                    width: columnWidth,
                }}>
          <View style={{
                    width: columnWidth,
                    borderWidth: 0,
                    borderBottomWidth: ThemeManager.style.borderWidth,
                    borderRightWidth: ThemeManager.style.borderWidth,
                    borderStyle: 'solid',
                    borderColor: ThemeManager.style.borderColor,
                    paddingTop: 36,
                }}>
            {this.renderRight(r, this.readKeys, r.readRights)}
          </View>
          <View style={{
                    width: columnWidth,
                    borderWidth: 0,
                    borderBottomWidth: ThemeManager.style.borderWidth,
                    borderRightWidth: ThemeManager.style.borderWidth,
                    borderStyle: 'solid',
                    borderColor: ThemeManager.style.borderColor,
                    paddingTop: 36,
                }}>
            {this.renderRights(r, this.writeKeys, r.writeRights)}
          </View>
        </View>);
        }
        return <View style={{ flexDirection: 'row' }}>{head}</View>;
    }
    renderLeftColumn(id, r, messagePrefix = '') {
        const views = [];
        r.forEach((v, k) => {
            views.push(<View style={{ paddingLeft: 8, paddingTop: 8, height: 36 }}>
          <MaterialText color={ThemeManager.style.black54} strong>
            {I18n.m.getMessage(k)}
          </MaterialText>
        </View>);
            views.push(this.renderLeftColumns(id, v, messagePrefix));
        });
        return views;
    }
    renderLeftColumns(id, r, messagePrefix = '') {
        const result = [];
        r.forEach((k, i) => {
            const desc = I18n.m.getMessage(`${messagePrefix}${k}_description`);
            result.push(<View key={`${id}_${k}`} style={{
                    paddingLeft: 8,
                    height: this.columnHeight,
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: i % 2 === 0 ? '#fafafa' : undefined,
                }}>
          <MaterialText numberOfLines={1} strong showToolTipOnCutText fixedWidth="100%">
            {I18n.m.getMessage(`${messagePrefix}${k}`)}
          </MaterialText>
          {desc === `${messagePrefix}${k}_description` ? null : (<MaterialText numberOfLines={1} showToolTipOnCutText fixedWidth="100%">
              {desc}
            </MaterialText>)}
        </View>);
        });
        return result;
    }
    renderRights(p, keys, r) {
        const result = [];
        keys.forEach((v, _k) => {
            result.push(<View style={{ paddingLeft: 8, paddingTop: 8, height: 36 }}>
          <MaterialText color={ThemeManager.style.black54} strong>
            {' '}
          </MaterialText>
        </View>);
            result.push(...this.renderRight(p, v, r));
        });
        return result;
    }
    renderRight(p, keys, r) {
        const result = [];
        keys.forEach((k, i) => {
            const disabledAndEnabled = k !== 'canChangeAllTickets' &&
                k !== 'commandCreateTicket' &&
                r['canChangeAllTickets'] &&
                this.writeKeys.get('tickets').includes(k);
            const disabled = disabledAndEnabled || p.baseRole == null || !(p.baseRole.readRights[k] || p.baseRole.writeRights[k]);
            result.push(<View key={`${p.id}_${k}_${r[k]}`} style={{
                    height: this.columnHeight,
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: i % 2 === 0 ? '#fafafa' : undefined,
                }}>
          <Switch disabled={disabled} key={`${k}_${disabledAndEnabled || r[k]}`} value={disabledAndEnabled || r[k]} onPress={this.onChangeRight(p, k)}/>
        </View>);
        });
        return result;
    }
}
