var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SwitchListItem = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-client/node_modules/react-native-web");
var _Switch = require("./Switch");
var _MaterialText = require("./text/MaterialText");
var _InfoButton = require("./InfoButton");
var _jsxRuntime = require("../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var SwitchListItem = exports.SwitchListItem = function (_PureComponent) {
  function SwitchListItem(props) {
    var _this;
    (0, _classCallCheck2.default)(this, SwitchListItem);
    _this = _callSuper(this, SwitchListItem, [props]);
    _this.onLayout = function (e) {
      _this.setState({
        leftWidth: e.nativeEvent.layout.width - 76
      });
    };
    _this.onPressText = function (_e) {
      if (_this.checkbox != null && !_this.props.disabled) {
        _this.checkbox.setValue(!_this.checkbox.getValue());
      }
    };
    _this.setCheckboxRef = function (ref) {
      _this.checkbox = ref;
    };
    _this.state = {
      leftWidth: 0
    };
    return _this;
  }
  (0, _inherits2.default)(SwitchListItem, _PureComponent);
  return (0, _createClass2.default)(SwitchListItem, [{
    key: "render",
    value: function render() {
      var _this$props2, _this$props3, _this$props4;
      var _this$props = this.props,
        title = _this$props.title,
        onPressText = _this$props.onPressText,
        initValue = _this$props.initValue,
        style = _this$props.style,
        helperText = _this$props.helperText;
      var leftWidth = this.state.leftWidth;
      var onPressAll = onPressText != null ? undefined : this.onPressText;
      var s = {
        padding: 8,
        backgroundColor: '#FFFFFF',
        paddingTop: 8,
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between'
      };
      if (style != null) {
        s = Object.assign({}, s, style);
      }
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: s,
        onLayout: this.onLayout,
        children: [(0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            width: leftWidth,
            alignItems: 'center',
            justifyContent: 'center'
          },
          children: [(0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
            style: {
              width: leftWidth,
              flexDirection: 'row',
              alignItems: 'center'
            },
            children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
              children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
                onPress: onPressAll,
                numberOfLines: 1,
                children: title
              })
            }), ((_this$props2 = this.props) == null ? void 0 : _this$props2.info) && (0, _jsxRuntime.jsx)(_InfoButton.InfoButton, {
              title: (_this$props3 = this.props) == null || (_this$props3 = _this$props3.info) == null ? void 0 : _this$props3.title,
              text: (_this$props4 = this.props) == null || (_this$props4 = _this$props4.info) == null ? void 0 : _this$props4.text
            })]
          }), helperText && (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            onPress: onPressAll,
            numberOfLines: 1,
            type: _MaterialText.MaterialTextTypes.Body2,
            children: helperText
          })]
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          accessible: true,
          accessibilityLabel: "switch",
          children: (0, _jsxRuntime.jsx)(_Switch.Switch, Object.assign({
            initValue: initValue,
            ref: this.setCheckboxRef
          }, this.props))
        })]
      });
    }
  }, {
    key: "blur",
    value: function blur() {}
  }, {
    key: "clear",
    value: function clear() {}
  }, {
    key: "focus",
    value: function focus() {}
  }, {
    key: "getId",
    value: function getId() {
      return '';
    }
  }, {
    key: "getValue",
    value: function getValue() {
      return this.checkbox.getValue();
    }
  }, {
    key: "setValue",
    value: function setValue(value) {
      return this.checkbox.setValue(value);
    }
  }]);
}(_react.PureComponent);