import color from 'color';
import { Card } from 'materialTheme/src/theme/components/Card';
import { Chip } from 'materialTheme/src/theme/components/chips/Chip';
import { ChipDialogForm } from 'materialTheme/src/theme/components/chips/ChipDialogForm';
import { DialogUp } from 'materialTheme/src/theme/components/DialogUp';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { RouterControl } from 'materialTheme/src/theme/routing/RouterControl';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import querystring from 'query-string';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { AddTicketTag } from 'upmesh-core/src/client/commands/tickets/AddTicketTag';
import { RemoveTicketTag } from 'upmesh-core/src/client/commands/tickets/RemoveTicketTag';
import { TicketStatus, TicketStatusColor } from 'upmesh-core/src/client/query/entities/TicketEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CurrentProject } from '../project/CurrentProject';
import { TicketTableProjectCell } from './TicketTableProjectCell';
import { TicketTableUserCell } from './TicketTableUserCell';
const notFoundImage = require('../../assets/img/no_tickets.png');
export class TicketTable extends PureComponent {
    constructor(props) {
        super(props);
        this.onUrlChange = () => {
            const tickets = TicketTable.highlightOpenedTicket(this.props.tickets);
            this.setState({ tickets });
        };
        this.editTags = (ticket) => (e) => {
            const asyncNow = async () => {
                try {
                    const projectTags = [];
                    const project = await UpmeshClient.instance.modals.project.getById(ticket.projectId);
                    if (project == null)
                        return;
                    const projectTagGroups = [];
                    for (const key in project.tagGroups) {
                        projectTagGroups.push({
                            backgroundColor: project.tagGroups[key].color,
                            groupName: project.tagGroups[key].groupName,
                            id: project.tagGroups[key].groupName,
                        });
                    }
                    project.tags?.forEach((t) => {
                        projectTags.push({
                            id: t.tagName,
                            groupId: t.groupName,
                            title: t.tagName,
                            backgroundColor: t.color,
                            secondTextLine: t.groupName,
                            thumbnail: <View style={{ height: 24, width: 24, borderRadius: 12, backgroundColor: t.color }}/>,
                        });
                    });
                    const ticketTags = [];
                    if (ticket != null && ticket.tags != null && ticket.tags.length > 0) {
                        ticket.tags.forEach((t) => {
                            ticketTags.push({
                                id: t.tagName,
                                title: t.tagName,
                                backgroundColor: t.color,
                                groupId: t.groupName,
                                secondTextLine: t.groupName,
                                thumbnail: <View style={{ height: 24, width: 24, borderRadius: 12, backgroundColor: t.color }}/>,
                            });
                        });
                    }
                    const openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
                    const listItems = [];
                    for (let i = 0; i < projectTags.length; i += 1) {
                        const aChip = projectTags[i];
                        if (aChip.title != null) {
                            listItems.push({
                                selected: ticketTags != null && ticketTags.length > 0
                                    ? ticketTags.findIndex((a) => a.title === aChip.title) > -1
                                    : false,
                                secondTextLine: aChip.secondTextLine,
                                thirdTextLine: aChip.thirdTextLine,
                                thumbnail: aChip.thumbnail ? { thumbnail: aChip.thumbnail, width: 24, rounded: true } : undefined,
                                title: aChip.title,
                                groupId: aChip.groupId,
                                id: aChip.id ? aChip.id : aChip.title,
                            });
                        }
                    }
                    DialogUp.instance?.open({
                        openPosition,
                        content: (<ChipDialogForm key="TagsChipDialogForm" title={I18n.m.getMessage('tags')} sortByGroup items={listItems} chipGroups={projectTagGroups} onCancel={DialogUp.instance.close} multiselect onSave={(selectedTags) => {
                                const tags = [];
                                if (selectedTags != null) {
                                    selectedTags.forEach((c) => {
                                        if (c.selected) {
                                            const tag = projectTags.find((a) => a.title === c.title);
                                            if (tag != null) {
                                                tags.push({
                                                    tagName: tag.title,
                                                    color: tag.backgroundColor,
                                                    groupName: tag.groupId,
                                                });
                                            }
                                        }
                                    });
                                }
                                DialogUp.instance?.close(() => {
                                    if (ticket.tags == null)
                                        ticket.tags = [];
                                    for (const t of tags) {
                                        if (ticket.tags.findIndex((j) => j.tagName === t.tagName) === -1) {
                                            const c = new AddTicketTag({ tag: { ...t } }, ticket.id);
                                            c.execute().catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
                                        }
                                    }
                                    for (const t of ticket.tags) {
                                        if (tags.findIndex((j) => j.tagName === t.tagName) === -1) {
                                            const c = new RemoveTicketTag({ tag: { ...t } }, ticket.id);
                                            c.execute().catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
                                        }
                                    }
                                });
                            }} canAddNewChips={false}/>),
                        contentPadding: false,
                        fullscreenResponsive: true,
                        showCloseButton: false,
                        showCloseIcon: false,
                    });
                }
                catch (err) {
                    DefaultErrorHandler.showDefaultErrorAlert(err);
                }
            };
            asyncNow().catch((err) => console.error(err));
        };
        this.renderTags = (item, columnData, _index) => {
            if (item == null)
                return <View />;
            const { style } = columnData;
            let chips = [];
            if (item.tags && item.tags.length > 0) {
                chips = item.tags.map((e) => {
                    return {
                        title: e.tagName,
                        groupName: e.groupName,
                        groupId: e.groupName,
                        backgroundColor: e.color,
                        textColor: '#ffffff',
                    };
                });
            }
            return (<View style={[{ justifyContent: 'center', overflow: 'hidden', alignItems: 'flex-start' }, style]} key={`ViewAroundTags${item.id}}`}>
        <View style={{
                    zIndex: 2,
                    width: 5000,
                    overflow: 'hidden',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}>
          <Ripple toolTip={I18n.m.getMessage('editTags')} onPress={this.editTags(item)} style={{
                    width: 36,
                    height: 36,
                    borderRadius: 18,
                    borderStyle: 'dotted',
                    borderColor: ThemeManager.style.borderColor,
                    borderWidth: ThemeManager.style.borderWidth,
                    justifyContent: 'center',
                    marginRight: 8,
                }}>
            <MaterialText centeredBox centeredText>
              {chips.length}
            </MaterialText>
          </Ripple>
          {chips.map((c) => (<Chip {...c} key={c.title} onPressChip={() => {
                        const { currentFilter, updateFilter } = this.props;
                        const letT = currentFilter.t != null && currentFilter.t.length > 0 ? [...currentFilter.t] : [];
                        const isIn = letT.findIndex((a) => a === c.title);
                        if (isIn > -1)
                            letT.splice(isIn, 1);
                        else if (isIn === -1)
                            letT.push(c.title);
                        const newFilter = { ...currentFilter, t: letT };
                        updateFilter(newFilter);
                    }}/>))}
        </View>
      </View>);
        };
        this.renderSubordinate = (item, columnData, _index) => {
            if (item == null || item.subTickets == null || item.subTickets.length === 0)
                return <View />;
            const { style } = columnData;
            let chips = [];
            chips = item.subTickets.map((s) => {
                return {
                    title: `#${s.ticketNumber}`,
                    onPressChip: (e) => Routing.instance.openDialog('ticket', { id: s.id })(e),
                };
            });
            return (<View style={[{ justifyContent: 'center', overflow: 'hidden', alignItems: 'flex-start' }, style]} key={`ViewAroundSubTickets${item.id}}`}>
        <View style={{
                    zIndex: 2,
                    width: 5000,
                    overflow: 'hidden',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}>
          <View style={{
                    borderRadius: ThemeManager.style.borderRadius,
                    backgroundColor: ThemeManager.style.chipDefaultBg,
                    justifyContent: 'center',
                    padding: 4,
                    marginRight: 8,
                    flexDirection: 'row',
                }}>
            <Icon icon="arrow-right-bottom" toolTip="" outerSize={24}/>
            <MaterialText centeredBox centeredText>
              {item.subTickets.length}
            </MaterialText>
          </View>
          {chips.map((c) => (<Chip {...c} key={c.title}/>))}
        </View>
      </View>);
        };
        this.renderSuperordinate = (item, columnData, _index) => {
            if (item == null || item.superTicket == null)
                return <View />;
            const { style } = columnData;
            const s = item.superTicket;
            return (<View style={[{ justifyContent: 'center', overflow: 'hidden', alignItems: 'flex-start' }, style]} key={`ViewAroundSuperTicket${item.id}}`}>
        <Chip title={`#${s.ticketNumber}`} onPressChip={(e) => Routing.instance.openDialog('ticket', { id: s.id })(e)}/>
      </View>);
        };
        this.getColumns = () => {
            const { showProject, hideTicketHierarchy } = this.props;
            const columns = [
                {
                    title: I18n.m.getMessage('id'),
                    keyInData: 'ticketNumber',
                    style: { width: 86 },
                    onCellPress: this.openTicket,
                    dataType: 'number',
                    minWidth: 86,
                },
                {
                    title: I18n.m.getMessage('ticket'),
                    keyInData: 'title',
                    style: { width: 300 },
                    onCellPress: this.openTicket,
                    dataType: 'string',
                },
            ];
            columns.push({
                title: I18n.m.getMessage('ticketType'),
                keyInData: 'type',
                style: { width: 200 },
                dataType: 'string',
                cellRenderer: this.renderType,
            });
            columns.push({
                title: I18n.m.getMessage('ticketsDetailsState'),
                keyInData: 'ticketStatusText',
                style: { width: 150 },
                dataType: 'string',
                cellRenderer: this.renderState,
            });
            columns.push({
                title: I18n.m.getMessage('ticketsDetailsDescription'),
                keyInData: 'description',
                onCellPress: this.openTicket,
                style: { width: 200 },
                dataType: 'string',
            });
            columns.push({
                title: I18n.m.getMessage('craft'),
                keyInData: 'craft',
                style: { width: 200 },
                dataType: 'string',
                cellRenderer: this.renderCraft,
            });
            columns.push({
                title: I18n.m.getMessage('ticketsDetailsCompletionOn'),
                keyInData: 'completionOn',
                style: { width: 150 },
                onCellPress: this.openTicket,
                dataType: 'Date',
            });
            columns.push({
                title: I18n.m.getMessage('createdAt'),
                keyInData: 'createdAt',
                style: { width: 150 },
                onCellPress: this.openTicket,
                dataType: 'Date',
            });
            columns.push({
                title: I18n.m.getMessage('creator'),
                keyInData: 'creatorName',
                style: { width: 300 },
                cellRenderer: (item, column, _index) => (<TicketTableUserCell key={`createdBy_${item.id}`} userId={item['createdBy']} user={item['creatorUser']} text={item['creatorName']} ticket={item} style={column.style}/>),
                dataType: 'string',
            });
            columns.push({
                title: I18n.m.getMessage('ticketsDetailsTitleAssignedToUser'),
                keyInData: 'assignee',
                style: { width: 300 },
                cellRenderer: (item, column, _index) => (<TicketTableUserCell key={`assignedToUserId${item.id}`} userId={item['assignedToUserId']} user={item['assigneeUser']} text={item['assignee'] ? item['assignee'] : ''} ticket={item} style={column.style}/>),
                dataType: 'string',
            });
            columns.push({
                title: I18n.m.getMessage('ticketsDetailsTitleApprover'),
                keyInData: 'approver',
                style: { width: 300 },
                dataType: 'string',
                cellRenderer: (item, column, _index) => (<TicketTableUserCell key={`approverUserId${item.id}`} userId={item['approverUserId']} user={item['approverUser']} text={item['approver'] ? item['approver'] : ''} ticket={item} style={column.style}/>),
            });
            columns.push({
                title: I18n.m.getMessage('tags'),
                keyInData: 'tags',
                dataType: 'string',
                style: { width: 130 },
                cellRenderer: this.renderTags,
            });
            if (!hideTicketHierarchy) {
                columns.push({
                    title: I18n.m.getMessage('ticketSuperordinate'),
                    keyInData: 'subTicketFrom',
                    dataType: 'string',
                    style: { width: 130 },
                    cellRenderer: this.renderSuperordinate,
                });
                columns.push({
                    title: I18n.m.getMessage('ticketSubordinate'),
                    keyInData: 'subTicketsCounter',
                    dataType: 'string',
                    style: { width: 130 },
                    cellRenderer: this.renderSubordinate,
                });
            }
            if (showProject === true) {
                columns.push({
                    title: I18n.m.getMessage('project'),
                    keyInData: 'projectId',
                    style: { width: 300 },
                    dataType: 'string',
                    cellRenderer: this.renderProject,
                });
            }
            else {
                const p = CurrentProject.instance.getCurrentProject();
                if (p != null && p.ticketLayouts != null && p.ticketLayouts.length > 0) {
                    const map = new Map();
                    for (let i = 0; i < p.ticketLayouts.length; i += 1) {
                        const l = p.ticketLayouts[i];
                        if (l.onTypes != null && l.onTypes.length > 0) {
                            l.fields.forEach((b) => {
                                if (b.customField && b.customField.type !== 'divider') {
                                    const ident = b.identifier != null ? b.identifier : b.label;
                                    const identValue = `${ident}Value`;
                                    let dataType = 'string';
                                    if (b.customField && b.customField.type === 'Date' && b.customField.options['withTime'])
                                        dataType = 'DateWithTime';
                                    else if (b.customField && b.customField.type === 'Date')
                                        dataType = 'Date';
                                    else if (b.customField && b.customField.type === 'number')
                                        dataType = 'number';
                                    if (!map.has(ident) && columns.findIndex((a) => a.title === b.label) === -1)
                                        map.set(ident, {
                                            title: b.label,
                                            keyInData: `fields.${ident}`,
                                            style: { width: 300 },
                                            dataType,
                                            onCellPress: b.customField && (b.customField.type === 'person' || b.customField.type === 'multiperson')
                                                ? undefined
                                                : this.openTicket,
                                            cellRenderer: b.customField && (b.customField.type === 'person' || b.customField.type === 'multiperson')
                                                ? (item, column, _index) => {
                                                    const idKey = `fields.${identValue}`;
                                                    const textKey = `fields.${ident}`;
                                                    return (<TicketTableUserCell key={`${idKey}_${item.id}`} userId={item[idKey]} text={item[textKey]} ticket={item} style={column.style}/>);
                                                }
                                                : undefined,
                                        });
                                }
                            });
                        }
                    }
                    const mapArray = Array.from(map.values());
                    columns.push(...mapArray);
                }
            }
            return columns;
        };
        this.renderState = (item, column, _index) => {
            let title = '?';
            switch (item.ticketStatus) {
                case TicketStatus.open:
                    title = 'ticketsDetailsStateopen';
                    break;
                case TicketStatus.processing:
                    title = 'ticketsDetailsStateprocessing';
                    break;
                case TicketStatus.closed:
                    title = 'ticketsDetailsStateclosed';
                    break;
                case TicketStatus.noStatus:
                    title = 'ticketsDetailsStateNoStatus';
                    break;
                case TicketStatus.checked:
                    title = 'ticketsDetailsStatechecked';
                    break;
                default:
                    title = '?';
            }
            return (<Ripple style={{ flex: 1 }} key={`Status1_${item.id}_${item.ticketStatus}`} onPress={(e) => this.openTicket(item, e)}>
        <View style={[column.style, { justifyContent: 'center', paddingHorizontal: 16, alignItems: 'flex-start' }]} key={`Status_${item.id}_${item.ticketStatus}`}>
          <View style={{
                    height: 24,
                    borderRadius: 12,
                    width: 'auto',
                    marginRight: 0,
                    paddingLeft: 8,
                    paddingRight: 8,
                    backgroundColor: TicketStatusColor.getColorForStatus(item.ticketStatus),
                    justifyContent: 'center',
                    borderColor: item.ticketStatus === TicketStatus.noStatus ? 'rgb(114, 114, 114)' : undefined,
                    borderWidth: item.ticketStatus === TicketStatus.noStatus ? 0.5 : undefined,
                }}>
            <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage(title)}</MaterialText>
          </View>
        </View>
      </Ripple>);
        };
        this.openTicket = (ticket, e) => {
            Routing.instance.openDialog('ticket', { id: ticket.id })(e);
        };
        this.renderType = (item, column, _index) => {
            if (item.type != null) {
                return (<Ripple key={`type${item.id}`} style={{ flex: 1 }} onPress={(e) => this.openTicket(item, e)}>
          <View key={`type2${item.id}`} style={[{ justifyContent: 'center', paddingHorizontal: 16 }, column.style]}>
            <MaterialText numberOfLines={1}>{item.type}</MaterialText>
          </View>
        </Ripple>);
            }
            return (<Ripple key={`type${item.id}`} style={{ flex: 1 }} onPress={(e) => this.openTicket(item, e)}>
        <View key={`type2${item.id}`} style={[{ justifyContent: 'center', paddingHorizontal: 16 }, column.style]}>
          <MaterialText>{I18n.m.getMessage('ticketTypeNotSelected')}</MaterialText>
        </View>
      </Ripple>);
        };
        this.renderCraft = (item, column, _index) => {
            if (item.craft != null) {
                return (<Ripple key={`craft${item.id}`} onPress={(e) => this.openTicket(item, e)} style={{ flex: 1 }}>
          <View key={`craft2${item.id}`} style={[{ justifyContent: 'center', paddingHorizontal: 16 }, column.style]}>
            <MaterialText>{item.craft}</MaterialText>
          </View>
        </Ripple>);
            }
            return (<Ripple key={`craft${item.id}`} style={{ flex: 1 }} onPress={(e) => this.openTicket(item, e)}>
        <View key={`craft2${item.id}`} style={[{ justifyContent: 'center', paddingHorizontal: 16 }, column.style]}>
          <MaterialText>{I18n.m.getMessage('ticketsCraftNotSelected')}</MaterialText>
        </View>
      </Ripple>);
        };
        this.renderProject = (item, column, _index) => <TicketTableProjectCell key={`project${item.id}`} ticket={item} style={column.style}/>;
        this.getRowKey = (t) => {
            return `${t.id}_${t.lastModifiedAt}_${RouterControl.instance.currentUrl.search.includes(t.id)}`;
        };
        this.showTicketOnPlan = (ticket) => (_e) => {
            let zoomLevel = 3;
            try {
                if (RouterControl.instance.currentUrl.search != null) {
                    const query = { ...querystring.parse(RouterControl.instance.currentUrl.search) };
                    if (query != null && query['zTo'] != null) {
                        const s = query['zTo'].toString().split('x');
                        if (s.length >= 3) {
                            const z = Number.parseFloat(s[2]);
                            if (z > zoomLevel)
                                zoomLevel = z;
                        }
                    }
                }
            }
            catch (e) {
                console.debug('cant get query search', e);
            }
            Routing.instance.changeQueryParameter({ zTo: `${ticket.planPositionX}x${ticket.planPositionY}x${zoomLevel.toString()}x${ticket.id}` }, true);
        };
        this.state = { tickets: TicketTable.highlightOpenedTicket(props.tickets) };
    }
    static getDerivedStateFromProps(nextProps) {
        return {
            tickets: TicketTable.highlightOpenedTicket(nextProps.tickets),
        };
    }
    componentDidMount() {
        RouterControl.instance.urlChanged.attach(this.onUrlChange);
    }
    componentWillUnmount() {
        RouterControl.instance.urlChanged.detach(this.onUrlChange);
    }
    static highlightOpenedTicket(ticktes) {
        const t = [...ticktes];
        if (RouterControl.instance.currentUrl.search != null &&
            RouterControl.instance.currentUrl.search.includes('d=ticket')) {
            const query = { ...querystring.parse(RouterControl.instance.currentUrl.search) };
            if (query != null && query['data'] != null) {
                const query2 = { ...querystring.parse(query['data'].toString()) };
                if (query2['id'] != null) {
                    const idInUrl = query2['id'];
                    for (let i = 0; i < t.length; i += 1) {
                        if (t[i].id === idInUrl) {
                            t[i].bgColor = color(ThemeManager.style.brandPrimary).alpha(0.16).toString();
                        }
                        else {
                            t[i].bgColor = undefined;
                        }
                    }
                }
            }
        }
        return [...t];
    }
    render() {
        const { height, onMultiSelect, selectedIDs, onChangeTicketOrder, planId, sortable, multiselectEnabled, viewId } = this.props;
        const { tickets } = this.state;
        return (<View style={{ width: '100%', height: 'auto' }}>
        <Card style={{ width: '100%', height, maxWidth: '100%', alignSelf: 'center' }}>
          <Table sortable={sortable} tableName={`TicketTable${CurrentProject.instance.getCurrentProjectId()}${viewId ?? ''}-`} keyExtractor={this.getRowKey} actionItemsLength={1} actions={(_item) => {
                return planId != null && planId !== 'all' && _item.planId != null
                    ? [{ icon: 'bullseye', onAction: this.showTicketOnPlan, toolTip: '' }]
                    : [];
            }} emptyTableImage={notFoundImage} emptyTableText="" emptyTableHint="" columns={this.getColumns()} maxHeight={height - 16} data={tickets} multiselectEnabled={multiselectEnabled} onMultiSelect={onMultiSelect} selectedIDs={selectedIDs} onChangeSort={onChangeTicketOrder}/>
        </Card>
      </View>);
    }
}
