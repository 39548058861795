var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Divider = Divider;
var _react = _interopRequireDefault(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _Icon = require("../Icon");
var _ThemeManager = require("../../ThemeManager");
var _MaterialText = require("../text/MaterialText");
var _DialogUp = require("../DialogUp");
var _DialogContent = require("../dialog/DialogContent");
var _DialogTitle = require("../dialog/DialogTitle");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function DividerDescription(props) {
  var _DialogUp$instance;
  return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
    children: [(0, _jsxRuntime.jsx)(_DialogTitle.DialogTitle, {
      iconRight: {
        icon: 'close',
        onPress: (_DialogUp$instance = _DialogUp.DialogUp.instance) == null ? void 0 : _DialogUp$instance.close,
        toolTip: ''
      },
      children: props.title
    }), (0, _jsxRuntime.jsx)(_DialogContent.DialogContent, {
      children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
        children: props.description
      })
    })]
  });
}
function Divider(props) {
  var openDescriptionDialog = function openDescriptionDialog(e) {
    var _DialogUp$instance2;
    var openPosition = {
      x: e.nativeEvent.pageX,
      y: e.nativeEvent.pageY
    };
    (_DialogUp$instance2 = _DialogUp.DialogUp.instance) == null || _DialogUp$instance2.open({
      openPosition: openPosition,
      content: (0, _jsxRuntime.jsx)(DividerDescription, {
        title: props.title,
        description: props.description
      }),
      contentPadding: false
    });
  };
  return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
    children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
      style: Object.assign({}, props.style, {
        paddingBottom: _ThemeManager.ThemeManager.style.contentPaddingValue / 2,
        height: 0,
        width: '100%',
        borderBottomWidth: _ThemeManager.ThemeManager.style.borderWidth,
        borderBottomColor: props.color || _ThemeManager.ThemeManager.style.borderColor,
        marginBottom: _ThemeManager.ThemeManager.style.contentPaddingValue / 2
      })
    }), (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
      style: {
        flexDirection: 'row'
      },
      children: [props.title && (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
        strong: true,
        additionalTextStyle: {
          lineHeight: 36
        },
        children: props.title
      }), props.description && (0, _jsxRuntime.jsx)(_Icon.Icon, {
        icon: "information-outline",
        toolTip: "",
        onPress: openDescriptionDialog
      })]
    })]
  });
}