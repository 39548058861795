import { Card } from 'materialTheme/src/theme/components/Card';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Fab } from 'materialTheme/src/theme/components/Fab';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { InfoButton } from 'materialTheme/src/theme/components/InfoButton';
import { I18n } from '../../i18n/I18n';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
import { UpdateCompanyMessageDialog } from './UpdateCompanyMessageDialog';
export class CompanyTicketstatusView extends PureComponent {
    constructor(props) {
        super(props);
        this.myRole = () => {
            return CompanyUserInfo.me == null ? 'user' : CompanyUserInfo.me.role;
        };
        this.statusKeys = [
            'ticketsDetailsStateopen',
            'ticketsDetailsStateprocessing',
            'ticketsDetailsStateclosed',
            'ticketsDetailsStatechecked',
        ];
        this.openEditDialog = (i) => (_e) => {
            if (this.myRole() === 'admin') {
                const { companySettings } = this.props;
                Dialog.instance?.open({
                    closeOnTouchOutside: false,
                    content: (<UpdateCompanyMessageDialog id={i.id} value={i.value} original={i.original} companySettings={companySettings}/>),
                    contentPadding: false,
                    scrollable: true,
                    showCloseIcon: true,
                });
            }
            else {
                Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') });
            }
        };
        this.state = {
            myRole: this.myRole(),
        };
    }
    componentDidUpdate(prevProps) {
        if (prevProps.companySettings !== this.props.companySettings ||
            prevProps.companySettings.messagesOverride !== this.props.companySettings.messagesOverride) {
            this.updateData();
        }
    }
    componentDidMount() {
        this.updateData();
    }
    updateData() {
        const { companySettings } = this.props;
        const data = [];
        this.statusKeys.forEach((k, i) => {
            data.push({
                id: k,
                original: I18n.m.getMessage(k, {}, {}, true),
                order: i + 1,
                value: companySettings.messagesOverride != null && companySettings.messagesOverride[k] != null
                    ? companySettings.messagesOverride[k]
                    : '',
            });
        });
        this.setState({ data });
    }
    componentWillUnmount() {
        if (Fab.instance != null)
            Fab.instance.close();
    }
    render() {
        const { data, myRole } = this.state;
        if (!(myRole === 'admin' || myRole === 'user'))
            return null;
        const { size } = this.props;
        const tableMaxHeight = size.contentHeight - ThemeManager.style.headerHeight - ThemeManager.style.getScreenRelativePixelSize(72);
        return (<View style={{
                width: '100%',
                maxWidth: '100%',
                paddingTop: ThemeManager.style.contentPaddingValue,
                alignSelf: 'center',
                paddingHorizontal: 8,
            }}>
        <View style={{ flexDirection: 'row', marginLeft: 8 + ThemeManager.style.contentPaddingValue }}>
          <MaterialText centeredBox type={MaterialTextTypes.H6}>
            {I18n.m.getMessage('ticketState')}
            <InfoButton text={I18n.m.getMessage('companySettingsTicketstatusInformation')}/>
          </MaterialText>
        </View>
        <Card style={{ width: '100%', height: 'auto' }}>
          <Table tableName="CompanyTicketstatus" actionItemsLength={1} actions={myRole === 'admin'
                ? (_item) => [
                    { icon: 'pencil-outline', onAction: this.openEditDialog, toolTip: I18n.m.getMessage('edit') },
                ]
                : undefined} maxHeight={tableMaxHeight} emptyTableHint={I18n.m.getMessage('companyTypeNoDataHint')} emptyTableText={I18n.m.getMessage('companyTypeNoDataText')} data={data} sortBy="order" sortDirection="asc" columns={[
                {
                    title: I18n.m.getMessage('ticketStateOrder'),
                    keyInData: 'order',
                    style: { width: 100 },
                    sortable: true,
                    dataType: 'number',
                },
                {
                    title: I18n.m.getMessage('ticketStateOriginal'),
                    keyInData: 'original',
                    style: { width: 350 },
                    sortable: true,
                    dataType: 'string',
                },
                {
                    title: I18n.m.getMessage('ticketStateCustom'),
                    keyInData: 'value',
                    style: { width: 350 },
                    sortable: true,
                    dataType: 'string',
                },
            ]}/>
        </Card>
      </View>);
    }
}
