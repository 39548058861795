import { ChipDialogForm } from 'materialTheme/src/theme/components/chips/ChipDialogForm';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { I18n } from '../../../i18n/I18n';
import { CurrentProject } from '../../project/CurrentProject';
export function BulkChangeDialogUserList(props) {
    const [memberList, setMemberList] = useState([]);
    const [init, setInit] = useState(false);
    useEffect(() => {
        const team = CurrentProject.instance.getCurrentProjectTeam();
        const memberList = [];
        team.forEach((member) => {
            memberList.push(member);
        });
        memberList.sort((a, b) => (a.user.getFullName() < b.user.getFullName() ? -1 : 1));
        setMemberList(memberList);
        setInit(true);
    }, []);
    const getHeader = () => {
        const { changedTopic } = props;
        if (changedTopic === 'assignee')
            return I18n.m.getMessage('bulkChangesChangeAssignee');
        if (changedTopic === 'approver')
            return I18n.m.getMessage('bulkChangesChangeApprover');
        if (changedTopic === 'watcher' || changedTopic === 'removeWatcher')
            return I18n.m.getMessage('bulkChangesAddWatcher');
        if (typeof changedTopic !== 'string')
            return changedTopic.label;
        return '';
    };
    const getMultiselect = () => {
        const { changedTopic } = props;
        if (typeof changedTopic !== 'string' && changedTopic.customField && changedTopic.customField.type === 'multiperson')
            return true;
        return false;
    };
    const getItem = (m, multiselect) => {
        let item = {
            title: m.user.getFullName(),
            onPress: () => {
                props.onSelectSingleUser(m.user.id);
            },
            id: m.user.id,
            secondTextLine: m.user.company,
            thumbnail: { thumbnail: <UserImage size={24} user={m.user}/>, width: 24, rounded: true },
        };
        if (multiselect) {
            item = {
                title: m.user.getFullName(),
                id: m.user.id,
                secondTextLine: m.user.company,
                thumbnail: { thumbnail: <UserImage size={24} user={m.user}/>, width: 24, rounded: true },
            };
        }
        return item;
    };
    const getUserList = () => {
        const { changedTopic, onSelectSingleUser } = props;
        const items = [];
        const multiselect = getMultiselect();
        for (const m of memberList) {
            items.push(getItem(m, multiselect));
        }
        if (changedTopic !== 'watcher' && changedTopic !== 'removeWatcher' && !multiselect) {
            items.unshift({
                title: I18n.m.getMessage('bulkChangesNotAssignedToUser'),
                onPress: () => {
                    onSelectSingleUser('0');
                },
                id: '0',
                thumbnail: {
                    thumbnail: (<View style={{
                            borderStyle: 'dashed',
                            borderWidth: ThemeManager.style.borderWidth,
                            borderRadius: ThemeManager.style.getScreenRelativePixelSize(12),
                            width: ThemeManager.style.getScreenRelativePixelSize(24),
                            height: ThemeManager.style.getScreenRelativePixelSize(24),
                            alignItems: 'center',
                            alignContent: 'center',
                            justifyContent: 'center',
                        }}>
              <Icon toolTip="" icon="help" iconSize={20} outerSize={ThemeManager.style.getScreenRelativePixelSize(24)} radius={0}/>
            </View>),
                    width: 24,
                    rounded: true,
                },
            });
        }
        return items;
    };
    const renderList = () => {
        const multiselect = getMultiselect();
        return (<View>
        <ChipDialogForm disableAutoSort title={getHeader()} items={getUserList()} multiselect={multiselect} showSelectedIcons={multiselect} onSave={props.onSave} onCancel={props.onCancel}/>
      </View>);
    };
    return !init ? <Spinner /> : renderList();
}
